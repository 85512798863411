import React from 'react';
import wearYourEyeglasses from '../../assets/svg/instructions/wear-your-eyeglasses.svg';

interface Props {

}

const WearYourEyeGlassesInstruction : React.FC<Props>  = () =>  {

    return (
      <>
            <div className='instruction__image'>
                <img src={wearYourEyeglasses} alt="mobile-distance-instruction" />
            </div>

            <div className='breadcrumb__container'>
                <div className='breadcrumb'></div>
                <div className='breadcrumb--selected'></div>
                <div className='breadcrumb'></div>
            </div>

            <div className='instruction__title'>
                <h2>Wear Your Eyeglasses</h2>
            </div>

            <div className='instruction__description'>
                <caption>Please put on your pair of glasses (if any) and keep them on for the duration of the game.</caption>
            </div>
        </>
    )
}

export default WearYourEyeGlassesInstruction;
import React, { useEffect, useState } from 'react';

interface Props {
    name: string,
    type?: 'svg' | 'image' | 'png',
    extension?: 'png' | 'jpg',
    className?: string
}

const Badge : React.FC<Props>  = ({
        name,
        type = 'svg',
        extension = 'png',
        className
    }) =>  {
    // VARIABLES
    const [ image, setImage ] = useState('');

    // METHODS
    const getFileExtension = () => {
        return type === 'svg' ? 'svg' : extension;
    }

    const loadImage = (imgName: string) => {
        const ext = getFileExtension();

        import(`../../assets/svg/badges/${imgName}.${ext}`).then(img => {
          setImage(img.default);
        });
    };

    const renderImage = () => {
        return <img src={image} alt="" className={className} />
    }

    // HOOKS
    useEffect(() => {
        loadImage(name);
    }, [name])

    return (
        <>
            { image && renderImage()}
        </>
    );
}

export default Badge;
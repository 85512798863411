import React, { useState, useRef, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import userSlice from '../../../store/slices/user-slice';
import backIcon from '../../../assets/svg/icons/arrow-back-white.svg';
import useGoto from '../../../utils/hooks/useGoto';
import routes from '../../../constants/routes.constants';

interface Props {
  handleCheckboxChange:any,
  searchText:any,
  handleSearch:any
 }

const Header : React.FC<Props> = ({
  handleCheckboxChange,
  searchText,
  handleSearch
}) => {
    const goto = useGoto();
    const dispatch = useDispatch();

    const [showFilter, setShowFilter] = useState(false)
    const [M, setM] = useState(true)
    const [T, setT] = useState(true)
    const [W, setW] = useState(true)
    const [Th, setTh] = useState(true)
    const [F, setF] = useState(true)
    const [Sat, setSat] = useState(true)
    const [Sun, setSun] = useState(true)

    const goToDashboard = () => {
      dispatch(userSlice.actions.setDashboardSlideId(2));
      setTimeout(() => {
          goto.routeWithTransition(
              routes.resultscreen, 
              routes.dashboard,
              'SLIDE_UP',
              500
          );
      }, 250);
  }

    return (
      <div className="header">
        <div className="upper-header">
        <button className="header-back" onClick={goToDashboard}><img src={backIcon} className="header-back-icon" alt="back-icon"/></button>
          <input type="text" value={searchText} onChange={handleSearch} className="search-input" placeholder="Enter Address or Postal Code"></input>
        </div>
        <div className="lower-header">
          {/* <button className="select-btn" onClick={() => {
            setShowFilter(!showFilter)
          }}>{`Day`}</button>
          {
            showFilter && (
            <div className="dropdown-days-selector">
              <input checked={M} type="checkbox" id="M" name="M" value="M" onChange={(e)=>{
                setM(!M)
                handleCheckboxChange(e)
              }}/>
              <label htmlFor="M">Monday</label><br/>

              <input checked={T} type="checkbox" id="T" name="T" value="T" onChange={(e)=>{
                setT(!T)
                handleCheckboxChange(e)
              }}/>
              <label htmlFor="T">Tuesday</label><br/>

              <input checked={W} type="checkbox" id="W" name="W" value="W" onChange={(e)=>{
                setW(!W)
                handleCheckboxChange(e)
              }}/>
              <label htmlFor="W">Wednesday</label><br/>

              <input checked={Th} type="checkbox" id="Th" name="Th" value="Th" onChange={(e)=>{
                setTh(!Th)
                handleCheckboxChange(e)
              }}/>
              <label htmlFor="Th">Thursday</label><br/>

              <input checked={F} type="checkbox" id="F" name="F" value="F" onChange={(e)=>{
                setF(!F)
                handleCheckboxChange(e)
              }}/>
              <label htmlFor="F">Friday</label><br/>

              <input checked={Sat} type="checkbox" id="Sat" name="Sat" value="Sat" onChange={(e)=>{
                setSat(!Sat)
                handleCheckboxChange(e)
              }}/>
              <label htmlFor="Sat">Saturday</label><br/>

              <input checked={Sun} type="checkbox" id="Sun" name="Sun" value="Sun" onChange={(e)=>{
                setSun(!Sun)
                handleCheckboxChange(e)
              }}/>
              <label htmlFor="Sun">Sunday</label><br/>
            </div>
            )
          } */}
          <button className="search-btn">{`Search`}</button>
        </div>

      </div>
    )
}

export default Header;
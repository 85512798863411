import React, { useEffect } from 'react';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';

import Button from '../../components/ui/Button';

import backIcon from '../../assets/svg/icons/arrow-back-white.svg';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/slices/user-slice';

interface Props {

}

const SelectMode: React.FC<Props> = () => {
    // VARIABLES
    const [B, E] = useBEM(routes.selectmode.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { id } = useSelector(getUser);

    useEffect(() => {
        connect();
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    const gotoName = () => {
        goto.routeWithTransition(
            routes.selectmode,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }

    const goToDashboard = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.selectmode,
                routes.dashboard,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const goToStory = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.selectmode,
                routes.story,
                'SLIDE_LEFT',
                500
            );
        }, 250);
    }

    const goToExplorer = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.selectmode,
                routes.explorermode,
                'SLIDE_DOWN',
                500
            );
        }, 250);
    }

    return (
        <div className={B() + ' page bg-light'}>
            <div className="flex-col">
                <div className="flex-row title-wrapper">
                    <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()} />
                    <h1>Select Mode</h1>
                </div>

                {localStorage.getItem("userCode") !== null ?
                    <div className={E('select__mode')}>
                        <h1>Pre-Screening Mode</h1>
                        <caption>Let's check your vision by playing our game</caption>

                        <div className={E('button__container')}>
                            <Button
                                text="Start"
                                type="secondary"
                                className={E('button--story')}
                                click={goToExplorer}
                                outlined={false}
                            />
                        </div>
                    </div> : <></>}

                <div className={E('explorer__mode')}>
                    <h1>Story Mode</h1>
                    <caption>
                        Read through the story to follow the journey of Brandyn and Ozzy
                    </caption>

                    <div className={E('button__container')}>
                        <Button
                            text="Start"
                            type="primary"
                            className={E('button--explorer')}
                            click={goToStory}
                            outlined={false}
                        />
                    </div>
                </div>

            </div>


        </div>
    )

}

export default withAnimationTransition(SelectMode, {
    entrance: 'FADE_IN'
});

import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'rc-progress';

import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import { getUser } from '../../store/slices/user-slice'; 
import { getBadges } from '../../store/slices/badges-slice';
import Badge from '../../components/badge/Badge';
import Navigation from '../../components/navigation/Navigation';
import check from '../../assets/svg/icons/check.svg';
import backIcon from '../../assets/svg/icons/arrow-back-white.svg';

interface Props {

}

const MyResults : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM(routes.myresults.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { distanceTestCompleted, astigmatismTestCompleted, nearVisionTestCompleted, 
            colorVisionTestCompleted, lightSensitivityTestCompleted } = useSelector(getUser);
    const { distanceTestPassed, astigmatismTestPassed, nearVisionTestPassed, 
            colorVisionTestPassed, lightSensitivityTestPassed } = useSelector(getUser);
    const { welcomeBadge,
            profileBadge,
            goodEyeSightBadge,
            glassesBadge,
            avatarBadge,
            storyBadge,
            eyeTestBadge,
            eyeAmbassadorBadge,
            eyeTestChampBadge,
            charityClinicSearcherBadge,
            eyeTipsChampBadge,
            upComingEventsAdvocateBadge } = useSelector(getBadges);
    const [welcomeCompletion, setWelcomeCompletion] = useState<number>(0);
    const [profileCompletion, setProfileCompletion] = useState<number>(0);
    const [goodEyeSightCompletion, setGoodEyeSightCompletion] = useState<number>(0);
    const [glassesCompletion, setGlassesCompletion] = useState<number>(0);
    const [avatarCompletion, setAvatarCompletion] = useState<number>(0);
    const [storyCompletion, setStoryCompletion] = useState<number>(0);
    const [eyeTestCompletion, setEyeTestCompletion] = useState<number>(0);
    const [eyeAmbassadorCompletion, setEyeAmbassadorCompletion] = useState<number>(0);
    const [eyeTestChampCompletion, setEyeTestChampCompletion] = useState<number>(0);
    const [charityClinicSearcherCompletion, setCharityClinicSearcherCompletion] = useState<number>(0);
    const [eyeTipsChampCompletion, setEyeTipsChampCompletion] = useState<number>(0);
    const [upComingEventsAdvocateCompletion, setUpComingEventsAdvocateCompletion] = useState<number>(0);

    // HOOKS
    useEffect(() => {
        connect();
        setCompletionRate();
    }, []);

    //METHODS
    const goToProfile = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.myresults, 
                routes.profile,
                'SLIDE_RIGHT',
                500
            );
        }, 250);
    }

    const setCompletionRate = () => {
        if (welcomeBadge) setWelcomeCompletion(100);
        if (profileBadge) setProfileCompletion(100);
        if (goodEyeSightBadge) setGoodEyeSightCompletion(100);
        if (glassesBadge) setGlassesCompletion(100);
        if (avatarBadge) setAvatarCompletion(100);
        if (storyBadge) setStoryCompletion(100);
        if (eyeTestBadge) setEyeTestCompletion(100);
        if (charityClinicSearcherBadge) setCharityClinicSearcherCompletion(100);
        if (eyeTipsChampBadge) setEyeTipsChampCompletion(100);
        if (upComingEventsAdvocateBadge) setUpComingEventsAdvocateCompletion(100);

        if (eyeAmbassadorBadge) {
            setEyeAmbassadorCompletion(100); 
        } else {
            setEyeAmbassadorCompletion(computeCompletionRate);
        }

        if (eyeTestChampBadge) {
            setEyeTestChampCompletion(100);
        } else {
            setEyeTestChampCompletion(computePassingRate);
        }
    }

    const computeCompletionRate = () => {
        let arrTestCompletion = [distanceTestCompleted, astigmatismTestCompleted, nearVisionTestCompleted, colorVisionTestCompleted, lightSensitivityTestCompleted];

        let testCompletionRate = 0;
        let testCompletedCounter = 0;

        arrTestCompletion.forEach(eyeTest => {
            if (eyeTest) testCompletedCounter = testCompletedCounter + 1;
        })
        testCompletionRate = (testCompletedCounter/5) * 100 ;

        return testCompletionRate;
    }

    const computePassingRate = () => {
        let arrTestPassing = [distanceTestPassed, astigmatismTestPassed, nearVisionTestPassed, colorVisionTestPassed, lightSensitivityTestPassed];
        let testPassingRate = 0;
        let testPassedCounter = 0;

        arrTestPassing.forEach(eyeTest => {
            if (eyeTest) testPassedCounter = testPassedCounter + 1;
        })
        testPassingRate = (testPassedCounter/5) * 100 ;

        return testPassingRate;
    }

    return(
        <div className={B() + ' page bg-light'}>
            <div className="flex-col">
                <div className="flex-row title-wrapper"> 
                    <img src={backIcon} alt="back-icon" onClick={() => goToProfile()}/>                        
                    <h1>My Results</h1>
                </div>

                <div className={E("results__first")}>
                    <div className={E("results-container")}>
                        <div className={E("results--image")}>
                            <Badge name="welcome-badge" />
                        </div>
                        
                        <div className={E("results__title-description")}>
                            { welcomeBadge ? 
                                <div className={E("results--title")}>
                                    <h2>Completed</h2> 
                                    <img src={check} alt="check-icon"/>
                                </div>
                            : 
                            <div className={E("results--title")}>
                                <h2>0% Completed</h2>
                            </div>
                            }

                            <div className={E("results--description")}>
                                <caption>Opened the app the first time.</caption>
                            </div>

                            <div className={E("results--progress")}>
                                <Line percent={welcomeCompletion} strokeWidth={5} trailWidth={2} strokeColor="#0186EF" trailColor='#E5E5E5'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("results-container")}>
                        <div className={E("results--image")}>
                            <Badge name="profile-badge" />
                        </div>
                        
                        <div className={E("results__title-description")}>
                            { profileBadge ? 
                                <div className={E("results--title")}>
                                    <h2>Completed</h2> 
                                    <img src={check} alt="check-icon"/>
                                </div>
                            : 
                            <div className={E("results--title")}>
                                <h2>0% Completed</h2>
                            </div>
                            }

                            <div className={E("results--description")}>
                                <caption>Filled out personal information.</caption>
                            </div>

                            <div className={E("results--progress")}>
                                <Line percent={profileCompletion} strokeWidth={5} trailWidth={2} strokeColor="#0186EF" trailColor='#E5E5E5'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("results-container")}>
                        <div className={E("results--image")}>
                            <Badge name="good-eyesight-badge" />
                        </div>
                        
                        <div className={E("results__title-description")}>
                            { goodEyeSightBadge ? 
                                <div className={E("results--title")}>
                                    <h2>Completed</h2> 
                                    <img src={check} alt="check-icon"/>
                                </div>
                            : 
                            <div className={E("results--title")}>
                                <h2>0% Completed</h2>
                            </div>
                            }

                            <div className={E("results--description")}>
                                <caption>You have a very good eyesight.</caption>
                            </div>

                            <div className={E("results--progress")}>
                                <Line percent={goodEyeSightCompletion} strokeWidth={5} trailWidth={2} strokeColor="#0186EF" trailColor='#E5E5E5'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("results-container")}>
                        <div className={E("results--image")}>
                            <Badge name="glasses-badge" />
                        </div>
                        
                        <div className={E("results__title-description")}>
                            { glassesBadge ? 
                                <div className={E("results--title")}>
                                    <h2>Completed</h2> 
                                    <img src={check} alt="check-icon"/>
                                </div>
                            : 
                            <div className={E("results--title")}>
                                <h2>0% Completed</h2>
                            </div>
                            }

                            <div className={E("results--description")}>
                                <caption>You are an eyeglass wearer.</caption>
                            </div>

                            <div className={E("results--progress")}>
                                <Line percent={glassesCompletion} strokeWidth={5} trailWidth={5} strokeColor="#0186EF" trailColor='#E5E5E5'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("results-container")}>
                        <div className={E("results--image")}>
                            <Badge name="avatar-badge" />
                        </div>
                        
                        <div className={E("results__title-description")}>
                            { avatarBadge ? 
                                <div className={E("results--title")}>
                                    <h2>Completed</h2> 
                                    <img src={check} alt="check-icon"/>
                                </div>
                            : 
                            <div className={E("results--title")}>
                                <h2>0% Completed</h2>
                            </div>
                            }

                            <div className={E("results--description")}>
                                <caption>You have selected an avatar.</caption>
                            </div>

                            <div className={E("results--progress")}>
                                <Line percent={avatarCompletion} strokeWidth={5} trailWidth={5} strokeColor="#0186EF" trailColor='#E5E5E5'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("results-container")}>
                        <div className={E("results--image")}>
                            <Badge name="story-badge" />
                        </div>
                        
                        <div className={E("results__title-description")}>
                            { storyBadge ? 
                                <div className={E("results--title")}>
                                    <h2>Completed</h2> 
                                    <img src={check} alt="check-icon"/>
                                </div>
                            : 
                            <div className={E("results--title")}>
                                <h2>0% Completed</h2>
                            </div>
                            }

                            <div className={E("results--description")}>
                                <caption>Completed story mode.</caption>
                            </div>

                            <div className={E("results--progress")}>
                                <Line percent={storyCompletion} strokeWidth={5} trailWidth={5} strokeColor="#0186EF" trailColor='#E5E5E5'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("results-container")}>
                        <div className={E("results--image")}>
                            <Badge name="eye-test-badge" />
                        </div>
                        
                        <div className={E("results__title-description")}>
                            { eyeTestBadge ? 
                                <div className={E("results--title")}>
                                    <h2>Completed</h2> 
                                    <img src={check} alt="check-icon"/>
                                </div>
                            : 
                            <div className={E("results--title")}>
                                <h2>0% Completed</h2>
                            </div>
                            }

                            <div className={E("results--description")}>
                                <caption>Finished one story and eye test.</caption>
                            </div>

                            <div className={E("results--progress")}>
                                <Line percent={eyeTestCompletion} strokeWidth={5} trailWidth={5} strokeColor="#0186EF" trailColor='#E5E5E5'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("results-container")}>
                        <div className={E("results--image")}>
                            <Badge name="eye-ambassador-badge" />
                        </div>
                        
                        <div className={E("results__title-description")}>
                            { eyeAmbassadorBadge ? 
                                <div className={E("results--title")}>
                                    <h2>Completed</h2> 
                                    <img src={check} alt="check-icon"/>
                                </div>
                            : 
                            <div className={E("results--title")}>
                                <h2>0% Completed</h2>
                            </div>
                            }

                            <div className={E("results--description")}>
                                <caption>Finished all story and eye tests.</caption>
                            </div>

                            <div className={E("results--progress")}>
                                <Line percent={eyeAmbassadorCompletion} strokeWidth={5} trailWidth={5} strokeColor="#0186EF" trailColor='#E5E5E5'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("results-container")}>
                        <div className={E("results--image")}>
                            <Badge name="eye-test-champ-badge" />
                        </div>
                        
                        <div className={E("results__title-description")}>
                            { eyeTestChampBadge ? 
                                <div className={E("results--title")}>
                                    <h2>Completed</h2> 
                                    <img src={check} alt="check-icon"/>
                                </div>
                            : 
                            <div className={E("results--title")}>
                                <h2>0% Completed</h2>
                            </div>
                            }

                            <div className={E("results--description")}>
                                <caption>Passed all eye tests.</caption>
                            </div>

                            <div className={E("results--progress")}>
                                <Line percent={eyeTestChampCompletion} strokeWidth={5} trailWidth={5} strokeColor="#0186EF" trailColor='#E5E5E5'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("results-container")}>
                        <div className={E("results--image")}>
                            <Badge name="charity-clinic-searcher-badge" />
                        </div>
                        
                        <div className={E("results__title-description")}>
                            { charityClinicSearcherBadge ? 
                                <div className={E("results--title")}>
                                    <h2>Completed</h2> 
                                    <img src={check} alt="check-icon"/>
                                </div>
                            : 
                            <div className={E("results--title")}>
                                <h2>0% Completed</h2>
                            </div>
                            }

                            <div className={E("results--description")}>
                                <caption>Accessed the clinic search.</caption>
                            </div>

                            <div className={E("results--progress")}>
                                <Line percent={charityClinicSearcherCompletion} strokeWidth={5} trailWidth={5} strokeColor="#0186EF" trailColor='#E5E5E5'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("results-container")}>
                        <div className={E("results--image")}>
                            <Badge name="eye-tips-champ-badge" />
                        </div>
                        
                        <div className={E("results__title-description")}>
                            { eyeTipsChampBadge ? 
                                <div className={E("results--title")}>
                                    <h2>Completed</h2> 
                                    <img src={check} alt="check-icon"/>
                                </div>
                            : 
                            <div className={E("results--title")}>
                                <h2>0% Completed</h2>
                            </div>
                            }

                            <div className={E("results--description")}>
                                <caption>Accessed eye care tips feature.</caption>
                            </div>

                            <div className={E("results--progress")}>
                                <Line percent={eyeTipsChampCompletion} strokeWidth={5} trailWidth={5} strokeColor="#0186EF" trailColor='#E5E5E5'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("results-container")}>
                        <div className={E("results--image")}>
                            <Badge name="charity-clinic-advocate-badge" />
                        </div>
                        
                        <div className={E("results__title-description")}>
                            { upComingEventsAdvocateBadge ? 
                                <div className={E("results--title")}>
                                    <h2>Completed</h2> 
                                    <img src={check} alt="check-icon"/>
                                </div>
                            : 
                            <div className={E("results--title")}>
                                <h2>0% Completed</h2>
                            </div>
                            }

                            <div className={E("results--description")}>
                                <caption>Used the partner clinic feature.</caption>
                            </div>

                            <div className={E("results--progress")}>
                                <Line percent={upComingEventsAdvocateCompletion} strokeWidth={5} trailWidth={5} strokeColor="#0186EF" trailColor='#E5E5E5'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Navigation page={routes.myresults.id} pageFrom={routes.myresults}/>
                </div>
            </div>

           
        </div>
    )

}

export default withAnimationTransition(MyResults, {
    entrance: 'FADE_IN'
});

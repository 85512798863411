import React, { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import MobileDistanceInstruction1Meter from '../../components/instructions/MobileDistanceInstruction1Meter';
import WearYourEyeGlassesInstruction from '../../components/instructions/WearYourEyeGlassesInstruction';
import wearYourEyeglasses from '../../assets/svg/instructions/wear-your-eyeglasses.svg';
import CoverRightEyeInstruction from '../../components/instructions/CoverRightEyeInstruction';
import Button from '../../components/ui/Button';
import backIcon from '../../assets/svg/icons/delete-icon.svg';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/slices/user-slice';
import MobileDistanceInstruction40cm from '../../components/instructions/MobileDistanceInstruction40cm';

interface Props {

}

const LightSensitivityTestInstructions: React.FC<Props> = () => {
    // VARIABLES
    const [B] = useBEM(routes.lightsensitivitytestinstructions.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { id } = useSelector(getUser);

    const swipeableStyles = {
        root: {
            padding: '0',
            height: '100%',
            width: '100%'
        },
        slideContainer: {
            padding: '0'
        }
    };

    useEffect(() => {
        connect();
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    const gotoName = () => {
        goto.routeWithTransition(
            routes.lightsensitivitytestinstructions,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }

    const goToSelectMode = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.lightsensitivitytestinstructions,
                routes.selectmode,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const goToLightSensitivityTest = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.lightsensitivitytestinstructions,
                routes.lightsensitivitytest,
                'SLIDE_LEFT',
                500
            );
        }, 250);
    }

    return (
        <div className={B() + ' page bg-yellow'}>
            <div className="flex-col">
                <div className="flex-row title-wrapper">
                    <img src={backIcon} alt="back-icon" onClick={() => goToSelectMode()} />
                    <h1>Contrast Game</h1>
                </div>

                <SwipeableViews enableMouseEvents={true} style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={0}>

                    {/* swipeableindex 0  */}
                    <div className="instructions">
                        <MobileDistanceInstruction40cm />

                        <div className="instructions__swipe">Swipe to proceed</div>
                    </div>

                    {/* swipeableindex 1  */}
                    <div className="instructions">
                        <>
                            <div className='instruction__image'>
                                <img src={wearYourEyeglasses} alt="mobile-distance-instruction" />
                            </div>

                            <div className='breadcrumb__container'>
                                <div className='breadcrumb'></div>
                                <div className='breadcrumb--selected'></div>
                            </div>

                            <div className='instruction__title'>
                                <h2>Wear Your Eyeglasses</h2>
                            </div>

                            <div className='instruction__description'>
                                <caption>Please put on your pair of glasses (if any) and keep them on for the duration of the game.</caption>
                            </div>
                        </>

                        <div className="instructions__button">
                            <Button
                                text="Play Game"
                                type="secondary"
                                outlined={false}
                                click={() => goToLightSensitivityTest()}
                            />
                        </div>
                    </div>

                    {/* swipeableindex 2  */}
                    {/* <div className="instructions">
                        <CoverRightEyeInstruction />

                        <div className='instruction__description'>
                            <caption>During the game, you will be tasked to indicate which way the open side of the 'C' is facing.</caption>
                        </div>

                        <div className="instructions__button">
                            <Button
                                text="Start Game"
                                type="secondary"
                                outlined={false}
                                click={() => goToLightSensitivityTest()}
                            />
                        </div>
                    </div> */}
                </SwipeableViews>
            </div>
        </div>
    )
}

export default withAnimationTransition(LightSensitivityTestInstructions, {
    entrance: 'FADE_IN'
});

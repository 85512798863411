import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../store/slices/user-slice';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import { data, days } from '../../constants/data';
import { rawData } from '../../constants/rawdata';
import Header from './components/Header';
import ResultPage from './components/ResultPage';

interface Props {

}

const ResultScreen: React.FC<Props> = () => {
  const [B, E] = useBEM('result-screen');
  const mock = data
  const goto = useGoto();
  const [dataFiltered, setDataFiltered] = useState([])
  const [dayFilter, setDayFilter] = useState(days)
  const [searchText, setSearchText] = useState('')
  const [connect] = useIndexedDB();
  const { id } = useSelector(getUser);

  useEffect(() => {
    connect();
  }, []);

  useEffect(() => {
    if (!id) gotoName();
  }, []);

  const gotoName = () => {
    goto.routeWithTransition(
      routes.resultscreen,
      routes.userinfo,
      'SLIDE_DOWN',
      500
    );
  }

  useEffect(() => {
    handleDayFilter()
  }, [dayFilter])

  const handleDayFilter = () => {
    let filtered: any = []

    const formattedJson = formatJSON(rawData)

    formattedJson.forEach((r: any, d: any) => {
      r.availability.forEach((a: any, v: any) => {
        a.day.forEach((ad: any, k: any) => {
          if (dayFilter.includes(ad)) {
            filtered.push(r)
          }
        })
      })
    })

    const unique = filtered && Array.from(new Set(filtered.map((item: any) => {
      return item
    })))

    setDataFiltered(unique)
  }

  useEffect(() => {
    handleSearchFilter()
  }, [searchText])

  const handleSearchFilter = () => {
    const formattedJson = formatJSON(rawData)
    const searched = formattedJson.filter((d: any) => d.storeName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
    setDataFiltered(searched)
  }

  const formatJSON = (rd: any) => {
    const res = rd && Array.from(new Set<any>(rd.map((item: any) => {
      return {
        "keystore": item.keystore,
        "storeName": item.storename,
        "storeAddress": item.storeaddress
      }
    })))


    const filtered = res.filter((r: any) => r.storeName)

    const newRes = filtered.map((f: any, k: any) => {
      return {
        ...f,
        "availability": getAvailability(f.keystore, rd)
      }
    })

    return newRes
  }

  const getAvailability = (ks: any, rd: any) => {
    const rawDataFiltered = rd.filter((r: any) => r.keystore === ks)

    const formatted = rawDataFiltered.map((rdf: any, k: any) => {
      return {
        "start": rdf.start,
        "end": rdf.end,
        "day": getDays(rdf)
      }
    })

    return formatted
  }

  const getDays = (r: any) => {
    const daysArr = []

    r.m && daysArr.push('M')
    r.t && daysArr.push('T')
    r.w && daysArr.push('W')
    r.th && daysArr.push('Th')
    r.f && daysArr.push('F')
    r.sat && daysArr.push('Sat')
    r.sun && daysArr.push('Sun')

    return daysArr
  }

  const handleCheckboxChange = (e: any) => {
    const { value, checked } = e.target

    if (!checked) {
      // Remove
      let array = dayFilter
      var removed = array.filter(e => e !== value)
      setDayFilter(removed)
    } else {
      // Add
      let array = dayFilter
      if (!array.includes(value)) {
        setDayFilter([...dayFilter, value])
      }

    }
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  return (
    <div className={B()}>
      <Header
        searchText={searchText}
        handleSearch={handleSearch}
        handleCheckboxChange={handleCheckboxChange}
      />
      <ResultPage
        data={dataFiltered}
      />
    </div>
  )

}

export default ResultScreen;
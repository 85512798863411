import React from 'react';
import ReactDOM from 'react-dom';
import useBEM from '../utils/hooks/useBEM';
import Icon from './ui/Icon';

interface Props {
    show: boolean,
    onClose?: () => void,
    hideCloseButton?: boolean,
    closeModalOnOutsideClick?: boolean,
    // height?: string,
    // width?: string,

    // radius?: string,
    className?: string,
    // transparent?: boolean,
    // style?: {
    //     width?: string,
    //     heigth?: string,
    //     borderRadius?: string
    // },
    // noPadding?: boolean,
    notification?: boolean,
    badge?: boolean,
    popup?: boolean
}

const Modal : React.FC<Props>  = ({
    onClose,
    show = false,
    children,
    hideCloseButton = false,
    closeModalOnOutsideClick = true,
    // height,
    // width,
    // radius,
    className = '',
    // transparent = false,
    // style,
    // noPadding = false,
    notification = false,
    badge = false,
    popup = false
}) =>  {

    // VARIABLES
    const [B, E] = useBEM('modal');
    const portal: any = document.getElementById("portal");
    // HOOKS

    // METHODS
    const onCloseHandler = () => {
        if(onClose){
            onClose();
        }
    }

    const overlayOnClickHandler = () => {
        if(closeModalOnOutsideClick) onCloseHandler()
    }

    // const customStyles = () => {
    //     return style ? style : {
    //         width,
    //         height,
    //         borderRadius: radius
    //     }
    // }

    const mofifierClass = () => {
        let classes: string[] = [];
        // if(transparent) classes.push('transparent');
        // if(noPadding) classes.push('no-padding');
        if(notification) classes.push('notification');
        if(badge) classes.push('badge');
        if(popup) classes.push('popup');

        return classes;
    }

    if(!show) return null;
    return ReactDOM.createPortal(
        <>
            <div className="modal-overlay" onClick={overlayOnClickHandler}></div>
            <div className={`${B(mofifierClass())} ${className}`}>
       
                {
                    !hideCloseButton && 
                    <button className={E('close')} onClick={() => onCloseHandler()}></button>
                }


                <div className={E('content')}>
                    {children}
                </div>

            </div>
        </>,
        portal
    )
}

export default Modal;
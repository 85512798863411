import { useState } from "react";
import { indexedDBConnect } from "../../store/indexed-db";

type indexDBType = [
    connect: (upgradeCallBack?: any) => void,
    get: (table: string, key: string) => Promise<any>,
]

const useIndexDB  = (): indexDBType =>  {

    const [db, setDb] = useState<any>();

    const connect = async (upgradeCallBack?: any) => {
        const indexdb = await indexedDBConnect(upgradeCallBack);
        setDb(indexdb);
    }

    const get = (table: string, key: string) => {
        const tx = db.transaction(table,'readonly');
        // tx.oncomplete = (ev: any) => {  }    
        // tx.onerror = (ev: any) => { }
        const store = tx.objectStore(table);
        const getRequest = store.get(key);

        return new Promise((resolved, reject) => {
            getRequest.onsuccess = (ev: any) => {
                resolved(ev.target.result);
            }
            getRequest.onerror = (err: any) => { 
                reject(err);
            }
        });        
    }

    return [
        connect,
        get
    ]
}

export default useIndexDB;
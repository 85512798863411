import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';

import Button from '../../components/ui/Button';
import arrowForward from '../../assets/svg/icons/arrow-forward-white.svg';
import backIcon from '../../assets/svg/icons/delete-icon.svg';
import colorVision from '../../assets/svg/explorer-mode/color-vision.svg';
import distanceTest from '../../assets/svg/explorer-mode/distance.svg';
import lightSensitivty from '../../assets/svg/explorer-mode/light-sensitivity.svg';
import nearVision from '../../assets/svg/explorer-mode/near-vision.svg';
import astigmatism from '../../assets/svg/explorer-mode/astigmatism.png';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/slices/user-slice';

interface Props {

}

const ExplorerMode: React.FC<Props> = () => {
    // VARIABLES
    const [B, E] = useBEM(routes.explorermode.id);
    const goto = useGoto();
    const [swipeableIndex, setSwipeableIndex] = useState<number>(0);
    const [connect] = useIndexedDB();
    const { id } = useSelector(getUser);

    const swipeableStyles = {
        root: {
            padding: '0',
            height: '100%',
            width: '100%'
        },
        slideContainer: {
            padding: '0'
        }
    };

    useEffect(() => {
        connect();
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    const gotoName = () => {
        goto.routeWithTransition(
            routes.explorermode,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }

    const goToSelectMode = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.explorermode,
                routes.selectmode,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const goToDistanceTest = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.explorermode,
                routes.distancetestinstructions,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const goToAstigmatistmTest = () => {
        // setTimeout(() => {
        //     goto.routeWithTransition(
        //         routes.explorermode,
        //         routes.astigmatismtestinstructions,
        //         'SLIDE_DOWN',
        //         500
        //     );
        // }, 250);
    }

    const goToNearVisionTest = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.explorermode,
                routes.nearvisiontestinstructions,
                'SLIDE_DOWN',
                500
            );
        }, 250);
    }

    const goToColorVisionTest = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.explorermode,
                routes.colorvisiontestinstructions,
                'SLIDE_DOWN',
                500
            );
        }, 250);
    }

    const goToLightSensitivityTest = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.explorermode,
                routes.lightsensitivitytestinstructions,
                'SLIDE_DOWN',
                500
            );
        }, 250);
    }

    const handleOnSwipe = (index: any) => {
        setSwipeableIndex(index);
    }

    return (
        <div className={B() + ' page bg-blue'}>
            <div className="flex-col">
                <div className="flex-row title-wrapper">
                    <img src={backIcon} alt="back-icon" onClick={() => goToSelectMode()} />
                    <h1>Test Your Vision</h1>
                </div>

                <SwipeableViews onChangeIndex={(index) => handleOnSwipe(index)} enableMouseEvents style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={swipeableIndex}>

                    <div className={E("explorer__card")}>
                        <div className={E("explorer__image")}>
                            <img src={distanceTest} alt="color-vision-test" />
                        </div>

                        <div className={E("explorer__description")}>
                            <h1>Distance Vision Game</h1>
                            <caption>This game will check how well you can see things far away.</caption>
                        </div>

                        <div className={E("explorer__button")}>
                            {/* <img src={arrowForward} alt="arrow-forward-icon" onClick={goToDistanceTest}/> */}
                            <Button
                                text="Start"
                                type="secondary"
                                outlined={false}
                                click={() => goToDistanceTest()}
                            />
                        </div>
                    </div>

                    <div className={E("explorer__card")}>
                        <div className={E("explorer__image")}>
                            <img src={nearVision} alt="color-vision-test" />
                        </div>

                        <div className={E("explorer__description")}>
                            <h1>Near Vision Game</h1>
                            <caption>This game will assess your eyes' ability to see details up close.</caption>
                        </div>

                        <div className={E("explorer__button")}>
                            {/* <img src={arrowForward} alt="arrow-forward-icon" onClick={goToNearVisionTest} /> */}
                            <Button
                                text="Start"
                                type="secondary"
                                outlined={false}
                                click={() => goToNearVisionTest()}
                            />
                        </div>
                    </div>

                    <div className={E("explorer__card")}>
                        <div className={E("explorer__image")}>
                            <img src={colorVision} alt="color-vision-test" />
                        </div>

                        <div className={E("explorer__description")}>
                            <h1>Color Vision Game</h1>
                            <caption>This game will assess your eyes' ability to see colors.</caption>
                        </div>

                        <div className={E("explorer__button")}>
                            {/* <img src={arrowForward} alt="arrow-forward-icon" onClick={goToColorVisionTest}/> */}
                            <Button
                                text="Start"
                                type="secondary"
                                outlined={false}
                                click={() => goToColorVisionTest()}
                            />
                        </div>
                    </div>

                    <div className={E("explorer__card")}>
                        <div className={E("explorer__image")}>
                            <img src={lightSensitivty} alt="color-vision-test" />
                        </div>

                        <div className={E("explorer__description")}>
                            <h1>Contrast Game</h1>
                            <caption>This game assesses how well your eyes can detect light intensity.</caption>
                        </div>

                        <div className={E("explorer__button")}>
                            {/* <img src={arrowForward} alt="arrow-forward-icon" onClick={goToLightSensitivityTest} /> */}
                            <Button
                                text="Start"
                                type="secondary"
                                outlined={false}
                                click={() => goToLightSensitivityTest()}
                            />
                        </div>
                    </div>

                    {/* <div className={E("explorer__card")}>
                        <div className={E("explorer__image")}>
                            <img src={astigmatism} alt="color-vision-test" />
                        </div>

                        <div className={E("explorer__description")}>
                            <h1>Astigmatism Game</h1>
                            <caption>This game will assess if your eyes are more prone to poor vision at night.</caption>
                        </div>

                        <div className={E("explorer__button")}>
                            <img src={arrowForward} alt="arrow-forward-icon" onClick={goToAstigmatistmTest} />
                            <Button
                                text="Start"
                                type="secondary"
                                outlined={false}
                                click={() => goToAstigmatistmTest()}
                            />
                        </div>
                    </div> */}
                </SwipeableViews>

                <div className={E("explorer__swipe")}>Swipe for next game</div>

                <div className={E('explorer__breadcrumb-container')}>
                    <div className={E('explorer__breadcrumb', [swipeableIndex === 0 ? 'selected' : ''])}></div>
                    <div className={E('explorer__breadcrumb', [swipeableIndex === 1 ? 'selected' : ''])}></div>
                    <div className={E('explorer__breadcrumb', [swipeableIndex === 2 ? 'selected' : ''])}></div>
                    <div className={E('explorer__breadcrumb', [swipeableIndex === 3 ? 'selected' : ''])}></div>
                    {/* <div className={E('explorer__breadcrumb', [swipeableIndex === 4 ? 'selected' : ''])}></div> */}
                </div>

            </div>


        </div>
    )

}

export default withAnimationTransition(ExplorerMode, {
    entrance: 'FADE_IN'
});

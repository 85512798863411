import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/user-slice';
import iRootState from './interface/root-state.interface';
import iUserStore from './interface/user-store.interface';
import iBadgesStore  from './interface/badges-store.interface';
import indexDBTransaction from './indexed-db';
import badgesSlice from './slices/badges-slice';

const loadFromLocalStorage = (): iRootState | undefined => {
    try {
        const serialisedState = localStorage.getItem("persistantState");
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState) as iRootState;
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}


const saveToLocalStorage = (state: iRootState) => {
    try {
      const serialisedState = JSON.stringify(state);
      localStorage.setItem("persistantState", serialisedState);
    } catch (e) {
      console.warn(e);
    }
}


const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        badges: badgesSlice.reducer
    },
    preloadedState: loadFromLocalStorage()
});


const transaction = indexDBTransaction((db: any) => {
    // Note: Create DB tables
    if(!db.objectStoreNames.contains('userStore')){
        db.createObjectStore('userStore', {
            keyPath: 'id'
        });    
    }
});
transaction.connect();

const saveUserOnIndexDB = (user: iUserStore) => {
    if(user.id){
        // Note: check if user id exist
        transaction.get('userStore', user.id, (result: any) => {
            if(!result){
                transaction.insert('userStore', user);
            } else { 
                transaction.update('userStore', user);
            }
        });
    }
}

let throttle: any;

store.subscribe( () => {
    const state = store.getState();
    
    clearTimeout(throttle);
    throttle = setTimeout(() => {
        saveUserOnIndexDB(state.user);
        saveToLocalStorage(state);
    }, 250);

})

export default store;
export const rawData = [
    {
        "keystore": 1,
        "storename": "Eye Vision Center",
        "storeaddress": "Talamban, Cebu City Cebu",
        "start": "10:00 AM",
        "end": "5:00 PM",
        "m": true,
        "t": false,
        "w": true,
        "th": false,
        "f": true,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 2,
        "storename": "Cinco-calderon Eye Clinic",
        "storeaddress": "Ground Floor M.diaz Bldg.don Jose, Avila St.,osmeña Blvd., Cebu City Cebu",
        "start": "9:00 AM",
        "end": "5:00 PM",
        "m": true,
        "t": false,
        "w": true,
        "th": false,
        "f": true,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 3,
        "storename": "Roble, Jeffrey G.",
        "storeaddress": "Rjm Bldg. Gf Osmena Blvd Capitol Site, Cebu City Cebu",
        "start": "9:00 AM",
        "end": "5:00 PM",
        "m": true,
        "t": false,
        "w": true,
        "th": false,
        "f": true,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 4,
        "storename": "H.p. Acebedo Opt. (junquera)",
        "storeaddress": "Junquera Cor. Sanciangko Usc Gym, Cebu City Cebu",
        "start": "9:00 AM",
        "end": "5:00 PM",
        "m": false,
        "t": true,
        "w": true,
        "th": true,
        "f": true,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 5,
        "storename": "Sarabia Optical - Robinsons Galleria",
        "storeaddress": "3/f Robinsons Galleria Gen. Maxilom Ext Tejero Cebu City, Cebu City Cebu",
        "start": "10:00 AM",
        "end": "5:00 PM",
        "m": true,
        "t": false,
        "w": true,
        "th": false,
        "f": true,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 6,
        "storename": "C & J Optical",
        "storeaddress": "Ground Floor Gaisano Tabunok, Talisay City Cebu",
        "start": "9:00 AM",
        "end": "5:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 7,
        "storename": "E. Fernandez Opt Clinic",
        "storeaddress": "Tabunok, Talisay Cebu",
        "start": "10:00 AM",
        "end": "5:00 PM",
        "m": false,
        "t": true,
        "w": false,
        "th": true,
        "f": false,
        "sat": true,
        "sun": false
    },
    {
        "keystore": 8,
        "storename": "Vintage Optical Express",
        "storeaddress": "Jp Laurel Avenue, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 9,
        "storename": "Eye Society Inc",
        "storeaddress": "2nd Level Gaisano Metro Ayala Mall, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 10,
        "storename": "Perez Optical",
        "storeaddress": "Grnd Flr, Ayala Center, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 11,
        "storename": "ASG",
        "storeaddress": "2nd Level Ayala Center, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 12,
        "storename": "Eye Society Inc",
        "storeaddress": "2nd Level Northwing Sm City, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 13,
        "storename": "D'vintage Vision",
        "storeaddress": "2/f, One Pavillion Mall, Duterte St., Banawa, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 14,
        "storename": "Nadine Optical",
        "storeaddress": "904 F. Ramos Ext., Capitol Site,, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 15,
        "storename": "Chong Hua Eye Institute",
        "storeaddress": "Cong Hua Hospital , Don Mariano Cui St., Fuente, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 16,
        "storename": "Bono Optical And Vision Therapy Center",
        "storeaddress": "Doctors Health & Science Bldg., Osmena Blvd., Capitol Site,, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 17,
        "storename": "Ideal Vision Center",
        "storeaddress": "Robinsons Fuente - Fuente Osmeña Cir, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 18,
        "storename": "Ideal Vision Center",
        "storeaddress": "Ayala Mall , Cardinal Rosales Ave, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 19,
        "storename": "Focal Sight By De Los Reyes",
        "storeaddress": "Level 2, Ayala Center, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 20,
        "storename": "Perez Optical",
        "storeaddress": "Ayala Center, Business Park, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 21,
        "storename": "Vintage Optical Express",
        "storeaddress": "3/f, Elizabeth Mall, N. Bacalso St., Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 22,
        "storename": "D'vintage Vision",
        "storeaddress": "2/f, Gaisano Capital South, Cor. Colon And Leon Kilat, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 23,
        "storename": "De Los Reyes Optical",
        "storeaddress": "P. Gullas St., Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 24,
        "storename": "D'vintage Vision",
        "storeaddress": "G/f, Gaisano Grand Mall Jai-alai, Mambaling, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 25,
        "storename": "Eye Sports By Ideal Vision Center",
        "storeaddress": "Sm - Juan Luna Avenue, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 26,
        "storename": "Yes - Your Eye Specialist",
        "storeaddress": "Sm , Juan Luna Avenue, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 27,
        "storename": "Ideal Vision Center",
        "storeaddress": "Sm - Juan Luna Avenue, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 28,
        "storename": "Eye Browse Optical",
        "storeaddress": "Lower Gf, Sm City, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 29,
        "storename": "De Los Reyes Optical",
        "storeaddress": "Lower G/f Sm City Cebu, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 30,
        "storename": "Yes - Your Eye Specialist",
        "storeaddress": "Sm Seaside, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 31,
        "storename": "Eye Browse",
        "storeaddress": "23a, Lower Ground Floor, Sm City , Nra, Mabolo, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 32,
        "storename": "De Los Reyes Optical",
        "storeaddress": "Lower Ground, Sm City, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 33,
        "storename": "Eye Browse",
        "storeaddress": "2/f, Sm Seaside, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 34,
        "storename": "Celebrity Optical",
        "storeaddress": "3/f, Sm Seaside, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 35,
        "storename": "De Los Reyes Optical",
        "storeaddress": "2/f, Sm Seaside,, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 36,
        "storename": "Celebrity Optical",
        "storeaddress": "3rd Flr Sm Sea Side, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 37,
        "storename": "Eye Browse Optical",
        "storeaddress": "2nd Flr Sm Sea Side, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 38,
        "storename": "Ideal Vision Center",
        "storeaddress": "Sm Seaside City, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 39,
        "storename": "Chong Hua Eye Mandaue",
        "storeaddress": "Chong Hua Hospital, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 40,
        "storename": "Sight Haven Optical",
        "storeaddress": "Ground Floor Parkmall Ouano Ave Mandaue, Mandaue City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 41,
        "storename": "Guizo Ophtha-opto Clinic",
        "storeaddress": "Guizo Mandaue A.s. Fortuna Ext. Across Mormons Church Guizo, Mandaue City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 42,
        "storename": "D'vintage Vision",
        "storeaddress": "G/f, Gaisano Capital, Ml Quezon Highway, Casuntingan, Mandaue City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 43,
        "storename": "Vintage Optical Express",
        "storeaddress": "G/f, Gaisano Grand Mandaue Centro, A. Rosario St., Mandaue City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 44,
        "storename": "Sight & Shades Optical",
        "storeaddress": "2nd Floor Gaisano Grand Mall Talamban, Cebu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 45,
        "storename": "Vintage Optical Express",
        "storeaddress": "G/f Gaisano Grand Mall, Centro A. Del Rosario St., Mandaue City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 46,
        "storename": "De Los Reyes Optical",
        "storeaddress": "2/f, South Town Center, Talisay City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 47,
        "storename": "Vintage Vision",
        "storeaddress": "2/f, Gaisano Mall Tabunok, Tabunok, Talisay City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 48,
        "storename": "De Los Reyes Optical",
        "storeaddress": "Pajo, Lapu-lapu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 49,
        "storename": "Vintage Vision",
        "storeaddress": "G/f, Gaisano Island Mall Mactan, Ml Quezon Highway, Lapu-lapu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 50,
        "storename": "Best Value Optical",
        "storeaddress": "Lower Ground Floor, Gaisano Island Mall Mactan, Ml Quezon Highway, Lapu-lapu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 51,
        "storename": "Eyeconic Optical",
        "storeaddress": "South Central Square, Highway Lawaan 3, Talisay City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 52,
        "storename": "S.fernandez Optical Clinic",
        "storeaddress": "Poblacion Ward 1, Minglanilla Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 53,
        "storename": "Vintage Optical Express",
        "storeaddress": "2/f, Gaisano Grand Mall, Minglanilla Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 54,
        "storename": "Vintage Optical Express",
        "storeaddress": "Ground Floor Gaisano Mactan, Lapu-lapu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 55,
        "storename": "Vintage Optical Express",
        "storeaddress": "Gaisano Grand Mall Hi-way Basak Cor. Kagodoy Rd., Lapu-lapu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 56,
        "storename": "Eye Crafter Optical",
        "storeaddress": "Upper Ground Floor Gaisano Grand Mall Of Mactan, Basak Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 57,
        "storename": "Vintage Vision",
        "storeaddress": "Upper G/f Gaisano Grand Mall Of Mactan, Basak, Lapu-lapu City Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 58,
        "storename": "Eye Browse Optical",
        "storeaddress": "2/f Sm City Consolacion, Consolacion Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 59,
        "storename": "Ideal Vision Center",
        "storeaddress": "Unit 152 Sm City Cons. North Rd.bgy.lamac Cons, Consolacion Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
    {
        "keystore": 60,
        "storename": "D'vintage Vision",
        "storeaddress": "Basement Floor, Gaisano Grand Mall Liloan, Liloan Poblacion, Liloan Cebu",
        "start": "1:00 PM",
        "end": "4:00 PM",
        "m": true,
        "t": false,
        "w": false,
        "th": false,
        "f": false,
        "sat": false,
        "sun": false
    },
]
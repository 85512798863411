import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import userSlice, { getUser } from '../../store/slices/user-slice'; 
import badgesSlice, { getBadges } from '../../store/slices/badges-slice';
import Button from '../../components/ui/Button';
import Badge from '../../components/badge/Badge';
import Modal from '../../components/Modal';
import ResultHappy from '../../components/result-emoji/Happy';
import ResultNeutral from '../../components/result-emoji/Neutral';
import ResultSad from '../../components/result-emoji/Sad';
import ProgressBar from '../../components/test-progressbar/ProgressBar';
import backIcon from '../../assets/svg/icons/delete-icon.svg';


const QUESTION_SLIDER_ID = 0;
const RESULT_SLIDER_ID = 1;


interface Props {

}

const NearVisionTest : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM(routes.nearvisiontest.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const dispatch = useDispatch();
    const { badgesEarned, progress, notifications } = useSelector(getUser);
    const { distanceTestCompleted, astigmatismTestCompleted, nearVisionTestCompleted, 
            colorVisionTestCompleted, lightSensitivityTestCompleted } = useSelector(getUser);
    const { distanceTestPassed, astigmatismTestPassed, nearVisionTestPassed, 
            colorVisionTestPassed, lightSensitivityTestPassed } = useSelector(getUser);
    const { eyeTestBadge, eyeAmbassadorBadge, eyeTestChampBadge } = useSelector(getBadges);
    const [slideViewID, setSlideViewID] = useState<number>(QUESTION_SLIDER_ID);
    const [passedEyeTest, setPassedEyeTest] = useState<boolean>(true);
    const [showEyeTestBadgeModal, setShowEyeTestBadgeModal] = useState<boolean>(false);
    const [imageAnimation, setImageAnimation] = useState<boolean>(false);
    const [pageAnimation, setPageAnimation] = useState<string>("");
    const [pageTitleAnimation, setPageTitleAnimation] = useState<string>("");
    const [showExitConfirmationModal, setShowExitConfirmationModal] = useState<boolean>(false);
    const [allTestCompleted, setAllTestCompleted] = useState<boolean>(false);
    const [allTestPassed, setAllTestPassed] = useState<boolean>(false);
    const [showEyeAmbassadorBadgeModal, setShowEyeAmbassadorBadgeModal] = useState<boolean>(false);
    const [showEyeTestChampBadgeModal, setShowEyeTestChampBadgeModal] = useState<boolean>(false);
    const [checkTestCompletion, setCheckTestCompletion] = useState<boolean>(false);
    
    const swipeableStyles = {
        root: {
          padding: '0',
          height: '100%',
          width: '100%'
        },
        slideContainer: {
          padding: '0'
        }
    };

    let notificationCount = 0;

    // HOOKS
    useEffect(() => {
        connect();
    }, []);

    useEffect(() => {
        if (slideViewID === RESULT_SLIDER_ID && !eyeTestBadge) setShowEyeTestBadgeModal(true);
        if (slideViewID === RESULT_SLIDER_ID) return dispatchResult();
    }, [slideViewID]);

    useEffect(() => {
        if (checkTestCompletion) {
            setAllTestCompleted(checkIfAllTestCompleted());
            setAllTestPassed(checkIfAllTestPassed());
        }
    }, [checkTestCompletion]);

    useEffect(() => {
        if (allTestCompleted) {
            if (slideViewID === RESULT_SLIDER_ID && !eyeAmbassadorBadge) setShowEyeAmbassadorBadgeModal(true);
        }
    }, [allTestCompleted]);

    useEffect(() => {
        if (allTestPassed) {
            if (slideViewID === RESULT_SLIDER_ID && !eyeTestChampBadge) setShowEyeTestChampBadgeModal(true); 
        }
    }, [allTestPassed]);

    useEffect(() => {
        if (imageAnimation) {
            setPageAnimation("expand-page");
            setPageTitleAnimation("expand-title");
        } else {
            setPageAnimation("");
            setPageTitleAnimation("");
        }
    }, [imageAnimation]);

    //METHODS
    const goToSelectMode = () => {
        updateNotificationInterruptedTest();
        setTimeout(() => {
            goto.routeWithTransition(
                routes.nearvisiontest, 
                routes.selectmode,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const getNotificationCount = () => {
        let count = 0;
        notifications?.forEach(() => {
            count = count + 1;
        });
        return count;
    }

    const updateNotificationsFirstEyeTestComplete = () => {
        notificationCount = getNotificationCount();
        let notifs_:Array<{id: number, description: string, activity: string, new:boolean}> = 
            ([{id: notificationCount + 1, description: "Congratulations! You've completed your first eye test! Keep going!", activity: "Completed first eye test (either on Story mode or Explorer mode)", new: true},
             {id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true}]);
        dispatch(userSlice.actions.saveNotifications({notification:notifs_}));
    }

    const updateNotificationsAllTestComplete = () => {
        notificationCount = getNotificationCount();
        let notifs_:Array<{id: number, description: string, activity: string, new:boolean}> = 
            ([{id: notificationCount + 1, description: "Congratulations! You have just completed all the eye tests! Take note of your results on the Results Page and head on to the Charitable Clinic Search page to call your desired clinic.", activity: "Completed all eye tests", new: true},
            {id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true}]);
        dispatch(userSlice.actions.saveNotifications({notification:notifs_}));
    }

    const updateNotificationsAllTestPassed = () => {
        notificationCount = getNotificationCount();
        let notifs_:Array<{id: number, description: string, activity: string, new:boolean}> = 
            ([{id: notificationCount + 1, description: "Congratulations! You have just passed all the eye tests! Take note of your results on the Results Page and head on to the Charitable Clinic Search page to call your desired clinic.", activity: "Completed all eye tests", new: true},
            {id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true}]);
        dispatch(userSlice.actions.saveNotifications({notification:notifs_}));
    }

    const updateNotificationInterruptedTest = () => {
        notificationCount = getNotificationCount();
        let notifs_:Array<{id: number, description: string, activity: string, new:boolean}> = 
            [{id: notificationCount + 1, description: "Oh sad, You haven't completed your eye tests yet. :(. Would you like to complete it now? Head to Explorer Mode to complete your previously started test!", activity: "Interrupted eye test completion", new: true}];
        dispatch(userSlice.actions.saveNotifications({notification:notifs_}));
    }

    const checkIfAllTestCompleted = () => {
        let retVal = true;
        if (!distanceTestCompleted || !astigmatismTestCompleted || !nearVisionTestCompleted || 
            !colorVisionTestCompleted || !lightSensitivityTestCompleted) retVal = false;
        return retVal;
    }

    const checkIfAllTestPassed = () => {
        let retVal = true;
        if (!distanceTestPassed || !astigmatismTestPassed || !nearVisionTestPassed || 
            !colorVisionTestPassed || !lightSensitivityTestPassed) retVal = false;
        return retVal;
    }

    const handleAnswerOnClick = (passed:boolean) => {
        if (!passed) setPassedEyeTest(passed);
        setSlideViewID(RESULT_SLIDER_ID);
    }

    const claimEyeTestBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress < 50) progress_ = 50;
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setEyeTestBadge(true));
        }, 250);
        updateNotificationsFirstEyeTestComplete();
        setShowEyeTestBadgeModal(false);
    }

    const claimEyeAmbassadorBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress <= 75) progress_ = 75;
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setEyeAmbassadorBadge(true));
        }, 250);
        updateNotificationsAllTestComplete();
        setShowEyeAmbassadorBadgeModal(false);
    }

    const claimEyeTestChampBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress <= 75) progress_ = 75;
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setEyeTestChampBadge(true));
        }, 250);
        updateNotificationsAllTestPassed();
        setShowEyeTestChampBadgeModal(false);
    }

    const renderResult = () => {
        const localData = JSON.parse(localStorage.getItem("persistantState") || '')
        const userID = localData.user.userInfoId;

        if (passedEyeTest) {
            const result = {
                nearVisionGameResult: "Happy",
                userInfoId: userID
            }
            fetch('https://ozzy-qa-app-sea-002.azurewebsites.net/ozzyapi/api/OzzyBear/AddResult', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(result),
            })
            .then((response) => response.json())
            .catch((error) => {
                console.error('Error:', error);
            });

            return <ResultHappy className="bg-blue" testType='nearvision'/>
            
        } else {
            const result = {
                nearVisionGameResult: "Sad",
                userInfoId: userID
            }
            fetch('https://ozzy-prd-app-sea-002.azurewebsites.net/ozzyapi/api/OzzyBear/AddResult', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(result),
            })
            .then((response) => response.json())
            .catch((error) => {
                console.error('Error:', error);
            });

            return <ResultSad className="bg-blue" testType='nearvision'/>
        }
    }

    const dispatchResult = () => {
        let nearVisionTestPassed_ = true;
        if (!passedEyeTest) nearVisionTestPassed_ = false;

        setTimeout(() => {
            dispatch(userSlice.actions.setNearVisionTestCompleted(true));
            dispatch(userSlice.actions.setNearVisionTestPassed(nearVisionTestPassed_));
            setCheckTestCompletion(true);
        }, 250);
    }

    const toggleAnimation = () => {
        setImageAnimation(prevState => !prevState);
    }

    return(
        <div className={B() + ` page bg-blue ${pageAnimation || ''}`}>
            <SwipeableViews enableMouseEvents={false} disabled style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={slideViewID}> 
                {/* SLIDER_ID 0 */}
                <div className="flex-col">
                    <div className={`flex-row title-wrapper ${pageTitleAnimation || ''}`}> 
                        <img src={backIcon} alt="back-icon" onClick={() => setShowExitConfirmationModal(true)}/>                        
                        <h1>Near Vision Test</h1>
                    </div>

                    {/* <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-0" type="png"/>
                    </div> */}

                    <div className={E("test__question-container")}>
                        <h1>Can you read all the lines?</h1>
                    </div>

                    <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleAnimation}>
                        <p className={E("fs1 test__text", [imageAnimation ? 'expand' : ''])}>After 20 minutes of close work, children should look at far objects for at least 20 seconds,</p>

                        <p className={E("fs2 test__text", [imageAnimation ? 'expand' : ''])}>and they should be spending time outdoors for at least 2 hours per day.</p>

                        <p className={E("fs3 test__text", [imageAnimation ? 'expand' : ''])}>Remember to go for regular eye checks at least once a year!</p>
                        
                        {/* <p className={E("fs4 test__text", [imageAnimation ? 'expand' : ''])}>of the visual acuity that they always had.</p> */}
                    </div>

                    <div className={E("test__button-container")}>
                        <div>
                            <Button 
                            text="Yes"
                            type="primary-small"
                            outlined={false}
                            click={() => handleAnswerOnClick(true)}
                            /> 
                        </div>

                        <div>
                            <Button 
                            text="No"
                            type="secondary-small"
                            outlined={false}
                            click={() => handleAnswerOnClick(false)}
                            /> 
                        </div>
                    </div>
                </div>

                {/* SLIDER_ID 1 */}
                <div className="result-emoji">
                    {renderResult()}
                </div>
           </SwipeableViews>

            <Modal show={showExitConfirmationModal} popup closeModalOnOutsideClick={false} hideCloseButton>
                <div className="confirmation">
                    <div className="confirmation__image-container">
                        <img src={backIcon} alt="back-icon" onClick={() => setShowExitConfirmationModal(false)}/>   
                    </div>

                    <div>
                        <h1>Are you sure?</h1>
                    </div>

                    <div>
                        <caption>Your progress will not be saved if you exit now.</caption>
                    </div>

                    <div className="confirmation__button-container">
                        <Button 
                            text="Yes"
                            type="primary"
                            outlined={false}
                            click={() => goToSelectMode()}
                        /> 
                    </div>

                </div>
            </Modal>

            <Modal show={showEyeTestBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>Wow! That's a first!</h1> <br />
                        <caption>You have earned the <p>Eye Test Goalie</p> badge</caption>
                    </div>
                    <Badge name="eye-test-badge"/>

                    <Button 
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeTestBadgeOnClick()}
                    /> 
                </div>
            </Modal>

            <Modal show={showEyeAmbassadorBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>That's impressive!</h1> <br />
                        <caption>You have earned the <p>Eye Test Ambassador</p> badge</caption>
                    </div>
                    <Badge name="eye-ambassador-badge"/>

                    <Button 
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeAmbassadorBadgeOnClick()}
                    /> 
                </div>
            </Modal>

            <Modal show={showEyeTestChampBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>You're a champ!</h1> <br />
                        <caption>You have earned the <p>Eye Test Champ</p> badge</caption>
                    </div>
                    <Badge name="eye-test-champ-badge"/>

                    <Button 
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeTestChampBadgeOnClick()}
                    /> 
                </div>
            </Modal>
        </div>
    )

}

export default withAnimationTransition(NearVisionTest, {
    entrance: 'FADE_IN'
});

export const data = [
    {
      "storeName": "MyEyeDr. in Bethesda",
      "storeAddress": "4917 Elm Street, Bethesda, MD 20814",
      "availability": [
        {
          "day": [
            "M",
            "W",
            "F"
          ],
          "start": "10:00 AM",
          "end": "01:00 PM"
        },
        {
          "day": [
            "T",
            "Th",
            "Sat"
          ],
          "start": "01:00 PM",
          "end": "04:00 PM"
        }
      ]
    },
    {
      "storeName": "EYE RX",
      "storeAddress": "4600 North Park Ave Plaza North, Chevy Chase, MD",
      "availability": [
        {
          "day": [
            "W",
            "F"
          ],
          "start": "12:00 PM",
          "end": "03:00 PM"
        },
        {
          "day": [
            "Sat"
          ],
          "start": "09:00 AM",
          "end": "12:00 PM"
        }
      ]
    },
    {
      "storeName": "MyEyeDr. in Chevy Chase",
      "storeAddress": "4465 Willard Avenue, Chevy Chase, MD 20815",
      "availability": [
        {
          "day": [
            "F"
          ],
          "start": "12:00 PM",
          "end": "03:00 PM"
        },
        {
          "day": [
            "Sat"
          ],
          "start": "09:00 AM",
          "end": "12:00 PM"
        }
      ]
    },
    {
      "storeName": "LUXOPTICS",
      "storeAddress": "21 Wisconsin Circle, Chevy Chase, MD 20815",
      "availability": [
        {
          "day": [
            "M"
          ],
          "start": "12:00 PM",
          "end": "03:00 PM"
        },
        {
          "day": [
            "Sat"
          ],
          "start": "09:00 AM",
          "end": "12:00 PM"
        }
      ]
    },
    {
      "storeName": "EYELAND Optical",
      "storeAddress": "12015 Rockville Pike, Ste E, Rockville, MD 20852",
      "availability": [
        {
          "day": [
            "Sun"
          ],
          "start": "09:00 AM",
          "end": "12:00 PM"
        }
      ]
    }
  ]

export const days = ["M","T","W","Th","F","Sat","Sun"]
import { bindActionCreators, createSlice, PayloadAction } from '@reduxjs/toolkit';

import iRootState from '../interface/root-state.interface';
import iUserStore from '../interface/user-store.interface';
import iNotifications from '../interface/notifications.interface';

const initialState: iUserStore = {
    
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setId: (state, action: PayloadAction<string>) => ( {...state, id: action.payload} ),
        setName: (state, action: PayloadAction<string>) => ( {...state, name: action.payload} ),
        setLastName: (state, action: PayloadAction<string>) => ( {...state, lastName: action.payload} ),
        setAge: (state, action: PayloadAction<number>) => ( {...state, age: action.payload} ),
        setGlasses: (state, action: PayloadAction<boolean>) => ( {...state, glasses: action.payload} ),
        setProgress: (state, action: PayloadAction<number>) => ( {...state, progress: action.payload} ),
        setBadgesEarned: (state, action: PayloadAction<number>) => ( {...state, badgesEarned: action.payload} ),
        setPreviousPage: (state, action: PayloadAction<string>) => ( {...state, previousPage: action.payload} ),
        setDashboardSlideId: (state, action: PayloadAction<number>) => ( {...state, dashboardSlideId: action.payload} ),
        setDistanceTestCompleted: (state, action: PayloadAction<boolean>) => ( {...state, distanceTestCompleted: action.payload} ),
        setDistanceTestPassed: (state, action: PayloadAction<boolean>) => ( {...state, distanceTestPassed: action.payload} ),
        setAstigmatismTestCompleted: (state, action: PayloadAction<boolean>) => ( {...state, astigmatismTestCompleted: action.payload} ),
        setAstigmatismTestPassed: (state, action: PayloadAction<boolean>) => ( {...state, astigmatismTestPassed: action.payload} ),
        setNearVisionTestCompleted: (state, action: PayloadAction<boolean>) => ( {...state, nearVisionTestCompleted: action.payload} ),
        setNearVisionTestPassed: (state, action: PayloadAction<boolean>) => ( {...state, nearVisionTestPassed: action.payload} ),
        setColorVisionTestCompleted: (state, action: PayloadAction<boolean>) => ( {...state, colorVisionTestCompleted: action.payload} ),
        setColorVisionTestPassed: (state, action: PayloadAction<boolean>) => ( {...state, colorVisionTestPassed: action.payload} ),
        setLightSensitivityTestCompleted: (state, action: PayloadAction<boolean>) => ( {...state, lightSensitivityTestCompleted: action.payload} ),
        setLightSensitivityTestPassed: (state, action: PayloadAction<boolean>) => ( {...state, lightSensitivityTestPassed: action.payload} ),
        // saveNotifications: (state, action:PayloadAction<iNotifications[]>) => ({ ...state, notifications: action.payload }),
        saveNotifications: (state, action:PayloadAction<{
            notification: iNotifications[]
        }>) => {
            const { notification } = action.payload;
            const currentNotifications: any = state.notifications;
            let newNotifications:any;
            if (currentNotifications) {
                newNotifications = [...currentNotifications, ...notification];
            } else {
                newNotifications = [...notification];
            }
            
            const newState = {
                ...state,
                notifications: newNotifications
            }

            return {
                ...state,
                ...newState
            }
        },
        setReadNotification: (state) => {
            let updatedNotifs = state.notifications?.map(
                i => ({...i, new: false})
              );
    
            const newState = {
                ...state,
                notifications: updatedNotifs
            }

            return {
                ...state,
                ...newState
            }
        },
                
        setUserData: (state, action: PayloadAction<iUserStore>) => ( {...state, ...action.payload} ),
        clearUserState: () => ({
            ...initialState
        })
    }
});

export const getUser = (state:iRootState) => state.user;
export default userSlice;
import React from 'react';
import Icon from './Icon';

interface Props {
    text?: string,
    className?: string,
    type?: 'primary' | 'secondary' | 'simple' | 'primary-small' | 'secondary-small',
    disabled?: boolean,
    icon?: string | React.ReactNode,
    iconPosition?: 'right' | 'left'
    outlined?: boolean,
    circle?: boolean,
    active?: boolean,
    weight?: 'bold' | 'normal',
    outlineType?: 'light' | 'colored',
    textSize?: 'default'  | 'small'
    click?: () => void,
}

const Button : React.FC<Props>  = ({
    text,
    className,
    type = "primary",
    disabled = false,
    icon,
    outlined = true,
    circle = false,
    active = false,
    iconPosition = 'right',
    weight = 'bold',
    outlineType = 'colored',
    textSize = 'default',
    click
}) =>  {
    // VARIABLES
    const finalClass = () => {
        const classes = [];
        if(className)classes.push(className);
        if(disabled)classes.push(`button--disabled`);
        if(!text)classes.push(`button--icon-only`);
        if(outlined)classes.push(`button--outlined`);
        if(circle)classes.push(`button--circle`);
        if(active)classes.push(`button--active`);
        classes.push(`button--icon-${iconPosition}`);
        classes.push(`button--${weight}`);
        classes.push(`button--outline-${outlineType}`);
        classes.push(`button--text-${textSize}`);

        classes.push(`button--${type}`);
        return classes.join(" ");
    }

    // HOOKS

    // METHODS
    const onClickHandler = () => {
        if(!click)return;
        click();
    }

    return (
        <button 
            disabled={disabled}
            onClick={onClickHandler}
            className={`button ${finalClass()}`}>
            {text}

            { icon && typeof icon === 'string' && <Icon className="button__icon" name={icon}/> }

            { icon && typeof icon !== 'string' && icon }
        </button>
    )
}

export default Button;
import React from 'react';
import mobileDistance1Meter from '../../assets/svg/instructions/mobile-distance-1meter.svg';

interface Props {

}

const MobileDistanceInstruction1Meter : React.FC<Props>  = () =>  {

    return (
      <>
            <div className='instruction__image'>
                <img src={mobileDistance1Meter} alt="mobile-distance-instruction" />
            </div>

            <div className='breadcrumb__container'>
                <div className='breadcrumb--selected'></div>
                <div className='breadcrumb'></div>
                <div className='breadcrumb'></div>
            </div>

            <div className='instruction__title'>
                <h2>Mobile Distance</h2>
            </div>

            <div className='instruction__description'>
                <caption>Place yourself 1 meter away from the screen of your mobile phone.</caption>
            </div>

            <div className='instruction__assistance-container'>
                <div className='instruction__assistance--warning'>
                    <h2>!</h2>
                </div>

                <div className='instruction__assistance--text'>
                    <caption>You may ask assistance from your friend or parent/guardian for this test.</caption>
                </div>
            </div>
            
        </>
    )
}

export default MobileDistanceInstruction1Meter;
import { bindActionCreators, createSlice, PayloadAction } from '@reduxjs/toolkit';

import iRootState from '../interface/root-state.interface';
import iBadgesStore from '../interface/badges-store.interface';

const initialState: iBadgesStore = {

}

const badgesSlice = createSlice({
    name: 'badges',
    initialState,
    reducers: {
        setWelcomeBadge: (state, action: PayloadAction<boolean>) => ( {...state, welcomeBadge: action.payload} ),
        setProfileBadge: (state, action: PayloadAction<boolean>) => ( {...state, profileBadge: action.payload} ),
        setGoodEyeSightBadge: (state, action: PayloadAction<boolean>) => ( {...state, goodEyeSightBadge: action.payload} ),
        setGlassesBadge: (state, action: PayloadAction<boolean>) => ( {...state, glassesBadge: action.payload} ),
        setAvatarBadge: (state, action: PayloadAction<boolean>) => ( {...state, avatarBadge: action.payload} ),
        setStoryBadge: (state, action: PayloadAction<boolean>) => ( {...state, storyBadge: action.payload} ),
        setEyeTestBadge: (state, action: PayloadAction<boolean>) => ( {...state, eyeTestBadge: action.payload} ),
        setEyeAmbassadorBadge: (state, action: PayloadAction<boolean>) => ( {...state, eyeAmbassadorBadge: action.payload} ),
        setEyeTestChampBadge: (state, action: PayloadAction<boolean>) => ( {...state, eyeTestChampBadge: action.payload} ),
        setCharityClinicSearcherBadge: (state, action: PayloadAction<boolean>) => ( {...state, charityClinicSearcherBadge: action.payload} ),
        setEyeTipsChampBadge: (state, action: PayloadAction<boolean>) => ( {...state, eyeTipsChampBadge: action.payload} ),
        setUpComingEventsAdvocateBadge: (state, action: PayloadAction<boolean>) => ( {...state, upComingEventsAdvocateBadge: action.payload} ),
        setBadgesData: (state, action: PayloadAction<iBadgesStore>) => ( {...state, ...action.payload} ),
        clearUserState: () => ({
            ...initialState
        })
    }
});

export const getBadges = (state:iRootState) => state.badges;
export default badgesSlice;
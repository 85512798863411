import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import userSlice from '../../store/slices/user-slice'; 
import useGoto from '../../utils/hooks/useGoto';
import routes from '../../constants/routes.constants';
import happyEmoji from '../../assets/svg/result-emoji/emoji-happy.svg';
import Button from '../../components/ui/Button';

interface Props {
    className?: string
    testType?: string
}

const HappyResult : React.FC<Props>  = ({
    className = '',
    testType = ''
}) =>  {
    // VARIABLES
    const goto = useGoto();
    const dispatch = useDispatch();
    const [contentTitle, setContentTitle] = useState<string>('');
    const [contentDescription, setContentDescription] = useState<string>('');

    //HOOKS
    useEffect(() => {
        setPageText();
    }, []);

    //METHODS
    const goToDashboard = () => {
        dispatch(userSlice.actions.setDashboardSlideId(2));
        setTimeout(() => {
            goto.routeWithTransition(
                routes.distancetest, 
                routes.dashboard,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const goToStory = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.distancetest, 
                routes.story,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const goToExplore = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.distancetest, 
                routes.explorermode,
                'SLIDE_UP',
                500
            );
            // if (localStorage.getItem("userCode") !== null) {
            //     goto.routeWithTransition(
            //         routes.distancetest, 
            //         routes.explorermode,
            //         'SLIDE_UP',
            //         500
            //     );
            // } else {
            //     goto.routeWithTransition(
            //         routes.distancetest, 
            //         routes.story,
            //         'SLIDE_UP',
            //         500
            //     );
            // }
        }, 250);
    }

    const setPageText = () => {
        switch (testType) {
            case 'distance':
                setContentTitle('Your visual acuity seems to be OK in both eyes');
                setContentDescription('However, to verify this result and the health of your eyes, we recommend you visit an eye care professional.');
                break;
            case 'astigmatism':
                setContentTitle('You don\'t seem to have an astigmatism problem');
                setContentDescription('However, to verify this result and the health of your eyes, we recommend you visit an eye care professional.');
                break;
            case 'nearvision':
                setContentTitle('You can read all the text from 40cm away');
                setContentDescription('Congratulations, your vision at near seems OK. We still recommend you go for regular eye checks at least once per year.');
                break;
            case 'colorvision':
                setContentTitle('Congratulations, you don\'t seem to have a color perception problem');
                setContentDescription('Feel free to redo this test regularly to monitor your vision. However, to verify the health of your eyes, don\'t hesitate to fix an appointment with an eye care professional.');
                break;
            case 'lightsensitivity':
                setContentTitle('Congratulations, your sensitivity to contrast in both eyes seems good');
                setContentDescription('However, to verify this result and the health of your eyes, we recommend you visit an eye care professional.');
                break;
        }
          
    }

    return (
    <>
        <div>
            <h1>That's very impressive!</h1>
        </div>

        <div className='result__image'>
            <img src={happyEmoji} alt="happy-emoji" />
        </div>

        <div className='result__container'>
            <div className={`icon ${className}`}>

            </div>

            <div className='result__content-title'>
                <h2>{contentTitle}</h2>
            </div>

            <div className='result__content-description'>
                <caption>{contentDescription}</caption>
            </div>

            <div className='result__button'>
                <Button 
                    text="Go Back to Story Mode"
                    type="secondary"
                    outlined={false}
                    click={() => goToStory()}
                /> 
            </div>

            <div className='result__button'>
                <Button 
                    text="Play Another Game"
                    type="secondary"
                    outlined={false}
                    click={() => goToExplore()}
                /> 
            </div>

            <div className='result__button'>
                <Button 
                    text="See an Eye Care Practitioner"
                    type="secondary"
                    outlined={false}
                    click={() => goToDashboard()}
                /> 
            </div>

        </div>
    </>
    )
}

export default HappyResult;
import React, { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import MobileDistanceInstruction1Meter from '../../components/instructions/MobileDistanceInstruction1Meter';
import WearYourEyeGlassesInstruction from '../../components/instructions/WearYourEyeGlassesInstruction';
import CoverRightEyeInstruction from '../../components/instructions/CoverRightEyeInstruction';
import Button from '../../components/ui/Button';
import backIcon from '../../assets/svg/icons/delete-icon.svg';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/slices/user-slice';

interface Props {

}

const DistanceTestInstructions: React.FC<Props> = () => {
    // VARIABLES
    const [B] = useBEM(routes.distancetestinstructions.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { id } = useSelector(getUser);

    const swipeableStyles = {
        root: {
            padding: '0',
            height: '100%',
            width: '100%'
        },
        slideContainer: {
            padding: '0'
        }
    };

    useEffect(() => {
        connect();
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    const gotoName = () => {
        goto.routeWithTransition(
            routes.distancetestinstructions,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }
    
    const goToSelectMode = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.distancetestinstructions,
                routes.selectmode,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const goToDistanceTest = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.distancetestinstructions,
                routes.distancetest,
                'SLIDE_LEFT',
                500
            );
        }, 250);
    }

    return (
        <div className={B() + ' page bg-orange'}>
            <div className="flex-col">
                <div className="flex-row title-wrapper">
                    <img src={backIcon} alt="back-icon" onClick={() => goToSelectMode()} />
                    <h1>Distance Vision</h1>
                </div>

                <SwipeableViews enableMouseEvents={true} style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={0}>

                    {/* swipeableindex 0  */}
                    <div className="instructions">
                        <MobileDistanceInstruction1Meter />

                        <div className="instructions__swipe">Swipe to proceed</div>
                    </div>

                    {/* swipeableindex 1  */}
                    <div className="instructions">
                        <WearYourEyeGlassesInstruction />
                    </div>

                    {/* swipeableindex 2  */}
                    <div className="instructions">
                        <CoverRightEyeInstruction />

                        <div className='instruction__description'>
                            <caption>During the game please observe which direction the letter 'E' is facing and click the arrow of the correct direction.</caption>
                        </div>

                        <div className="instructions__button">
                            <Button
                                text="Start Game"
                                type="secondary"
                                outlined={false}
                                click={() => goToDistanceTest()}
                            />
                        </div>
                    </div>
                </SwipeableViews>
            </div>

        </div>
    )
}

export default withAnimationTransition(DistanceTestInstructions, {
    entrance: 'FADE_IN'
});

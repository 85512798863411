import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import userSlice, { getUser } from '../../store/slices/user-slice';
import badgesSlice, { getBadges } from '../../store/slices/badges-slice';
import Button from '../../components/ui/Button';
import Badge from '../../components/badge/Badge';
import Modal from '../../components/Modal';
import ResultHappy from '../../components/result-emoji/Happy';
import ResultNeutral from '../../components/result-emoji/Neutral';
import ResultSad from '../../components/result-emoji/Sad';
import ProgressBar from '../../components/test-progressbar/ProgressBar';
import backIcon from '../../assets/svg/icons/delete-icon.svg';
import letterC from '../../assets/svg/lightsensitivity-test/letterC.svg';
import jungle from '../../assets/svg/lightsensitivity-test/Jungle Scene@2x.png'
import cave from '../../assets/svg/lightsensitivity-test/Cave Scene@2x.png'
import underwater from '../../assets/svg/lightsensitivity-test/Underwater Scene@2x.png'
import coverLeftEye from '../../assets/svg/pop-up/cover-left-eye.svg';
import happyEmoji from '../../assets/svg/result-emoji/emoji-happy.svg';

const STAGE1_QUESTION_SLIDER_ID = 0;
const STAGE1_ANSWER_SLIDER_ID = 1;
const STAGE2_QUESTION_SLIDER_ID = 2;
const STAGE2_ANSWER_SLIDER_ID = 3;
const STAGE3_QUESTION_SLIDER_ID = 4;
const STAGE3_ANSWER_SLIDER_ID = 5;
const RESULT_SLIDER_ID = 6;

const slideArray = [
    STAGE1_ANSWER_SLIDER_ID,
    STAGE2_ANSWER_SLIDER_ID,
    STAGE3_ANSWER_SLIDER_ID
]

interface Props {

}

const LightSensitivityTest: React.FC<Props> = () => {
    // VARIABLES
    const [B, E] = useBEM(routes.lightsensitivitytest.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { id } = useSelector(getUser);
    const dispatch = useDispatch();
    const { badgesEarned, progress, notifications } = useSelector(getUser);
    const { distanceTestCompleted, astigmatismTestCompleted, nearVisionTestCompleted,
        colorVisionTestCompleted, lightSensitivityTestCompleted } = useSelector(getUser);
    const { distanceTestPassed, astigmatismTestPassed, nearVisionTestPassed,
        colorVisionTestPassed, lightSensitivityTestPassed } = useSelector(getUser);
    const { eyeTestBadge, eyeAmbassadorBadge, eyeTestChampBadge } = useSelector(getBadges);
    //const [slideViewID, setSlideViewID] = useState<number>(STAGE1_QUESTION_SLIDER_ID);
    const [letterClassName, setLetterClassName] = useState<string>('');
    const [showEyeTestBadgeModal, setShowEyeTestBadgeModal] = useState<boolean>(false);
    const [showExitConfirmationModal, setShowExitConfirmationModal] = useState<boolean>(false);
    const [imageAnimation, setImageAnimation] = useState<boolean>(false);
    const [pageAnimation, setPageAnimation] = useState<string>("");
    const [pageTitleAnimation, setPageTitleAnimation] = useState<string>("");
    const [allTestCompleted, setAllTestCompleted] = useState<boolean>(false);
    const [allTestPassed, setAllTestPassed] = useState<boolean>(false);
    const [showEyeAmbassadorBadgeModal, setShowEyeAmbassadorBadgeModal] = useState<boolean>(false);
    const [showEyeTestChampBadgeModal, setShowEyeTestChampBadgeModal] = useState<boolean>(false);
    const [checkTestCompletion, setCheckTestCompletion] = useState<boolean>(false);
    const [swipeableIndex, setSwipeableIndex] = useState<number>(0);
    const [TotalCorrectAnswers, setTotalCorrectAnswers] = useState<number>(0);
    const [ConsecutiveWrongAnswerCounter, setConsecutiveWrongAnswerCounter] = useState<number>(0);
    const [testResult, setTestResult] = useState<string>("");

    const [question1, setQuestion1] = useState<number>(0);
    const [question2, setQuestion2] = useState<number>(0);
    const [question3, setQuestion3] = useState<number>(0);

    const [usedQuestions, setUsedQuestions] = useState<number[]>([])

    const swipeableStyles = {
        root: {
            padding: '0',
            height: '100%',
            width: '100%'
        },
        slideContainer: {
            padding: '0'
        }
    };

    let notificationCount = 0;
    // let usedQuestions: number[] = []; //used for storing questions already displayed to prevent repeating questions

    // HOOKS
    useEffect(() => {
        connect();
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    useEffect(() => {
        if (imageAnimation) {
            setPageAnimation("expand-page");
            setPageTitleAnimation("expand-title");
        } else {
            setPageAnimation("");
            setPageTitleAnimation("");
        }
    }, [imageAnimation]);

    useEffect(() => {
        if (imageAnimation) toggleExpandAnimation();
        if (swipeableIndex === RESULT_SLIDER_ID && !eyeTestBadge) setShowEyeTestBadgeModal(true);
        if (swipeableIndex === RESULT_SLIDER_ID) return dispatchResult();

        const minCeiled = Math.ceil(1);
        const maxFloored = Math.floor(4);
        let question = 0;

        //assign question to state
        //1 - Parrots
        //2 - Bats
        //3 - Jellyfish
        if (swipeableIndex == STAGE1_QUESTION_SLIDER_ID) {
            do {
                question = Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
            } while (usedQuestions.includes(question)); //keep randomizing as if the value is already in the array
            setUsedQuestions(prevState => [...prevState, question]);
            setQuestion1(question);
        } else if (swipeableIndex == STAGE2_QUESTION_SLIDER_ID) {
            do {
                question = Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
            } while (usedQuestions.includes(question)); //keep randomizing as if the value is already in the array
            setUsedQuestions(prevState => [...prevState, question]);
            setQuestion2(question);
        } else if (swipeableIndex == STAGE3_QUESTION_SLIDER_ID) {
            do {
                question = Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
            } while (usedQuestions.includes(question)); //keep randomizing as if the value is already in the array
            setUsedQuestions(prevState => [...prevState, question]);
            setQuestion3(question);
        }

        console.log(usedQuestions);

    }, [swipeableIndex]);

    useEffect(() => {
        if (checkTestCompletion) {
            setAllTestCompleted(checkIfAllTestCompleted());
            setAllTestPassed(checkIfAllTestPassed());
        }
    }, [checkTestCompletion]);

    useEffect(() => {
        if (allTestCompleted) {
            if (swipeableIndex === RESULT_SLIDER_ID && !eyeAmbassadorBadge) setShowEyeAmbassadorBadgeModal(true);
        }
    }, [allTestCompleted]);

    useEffect(() => {
        if (allTestPassed) {
            if (swipeableIndex === RESULT_SLIDER_ID && !eyeTestChampBadge) setShowEyeTestChampBadgeModal(true);
        }
    }, [allTestPassed]);

    useEffect(() => {
        setSwipeableIndex(STAGE1_QUESTION_SLIDER_ID);
    }, []);

    //METHODS
    const gotoName = () => {
        goto.routeWithTransition(
            routes.lightsensitivitytest,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }

    const goToSelectMode = () => {
        updateNotificationInterruptedTest();
        setTimeout(() => {
            goto.routeWithTransition(
                routes.distancetest,
                routes.selectmode,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const getNotificationCount = () => {
        let count = 0;
        notifications?.forEach(() => {
            count = count + 1;
        });
        return count;
    }

    const updateNotificationsFirstEyeTestComplete = () => {
        notificationCount = getNotificationCount();
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> =
            ([{ id: notificationCount + 1, description: "Congratulations! You've completed your first eye test! Keep going!", activity: "Completed first eye test (either on Story mode or Explorer mode)", new: true },
            { id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true }]);
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const updateNotificationsAllTestComplete = () => {
        notificationCount = getNotificationCount();
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> =
            ([{ id: notificationCount + 1, description: "Congratulations! You have just completed all the eye tests! Take note of your results on the Results Page and head on to the Charitable Clinic Search page to call your desired clinic.", activity: "Completed all eye tests", new: true },
            { id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true }]);
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const updateNotificationsAllTestPassed = () => {
        notificationCount = getNotificationCount();
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> =
            ([{ id: notificationCount + 1, description: "Congratulations! You have just passed all the eye tests! Take note of your results on the Results Page and head on to the Charitable Clinic Search page to call your desired clinic.", activity: "Completed all eye tests", new: true },
            { id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true }]);
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const updateNotificationInterruptedTest = () => {
        notificationCount = getNotificationCount();
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> =
            [{ id: notificationCount + 1, description: "Oh sad, You haven't completed your eye tests yet. :(. Would you like to complete it now? Head to Explorer Mode to complete your previously started test!", activity: "Interrupted eye test completion", new: true }];
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const checkIfAllTestCompleted = () => {
        let retVal = true;
        if (!distanceTestCompleted || !astigmatismTestCompleted || !nearVisionTestCompleted ||
            !colorVisionTestCompleted || !lightSensitivityTestCompleted) retVal = false;
        return retVal;
    }

    const checkIfAllTestPassed = () => {
        let retVal = true;
        if (!distanceTestPassed || !astigmatismTestPassed || !nearVisionTestPassed ||
            !colorVisionTestPassed || !lightSensitivityTestPassed) retVal = false;
        return retVal;
    }

    const claimEyeTestBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress < 50) progress_ = 50;
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setEyeTestBadge(true));
        }, 250);
        updateNotificationsFirstEyeTestComplete();
        setShowEyeTestBadgeModal(false);
    }

    const claimEyeAmbassadorBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress <= 75) progress_ = 75;
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setEyeAmbassadorBadge(true));
        }, 250);
        updateNotificationsAllTestComplete();
        setShowEyeAmbassadorBadgeModal(false);
    }

    const claimEyeTestChampBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress <= 75) progress_ = 75;
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setEyeTestChampBadge(true));
        }, 250);
        updateNotificationsAllTestPassed();
        setShowEyeTestChampBadgeModal(false);
    }

    const toggleExpandAnimation = () => {
        setImageAnimation(prevState => !prevState);
    }

    const handleOnSwipe = (index: any) => {
        setSwipeableIndex(index);
    }

    const renderPageTitle = () => {
        return <div className={`flex-row title-wrapper ${pageTitleAnimation || ''}`}>
            <img src={backIcon} alt="back-icon" onClick={() => setShowExitConfirmationModal(true)} />
            <h1>Contrast Game</h1>
        </div>
    }

    const renderProgressBar = (stage: number) => {
        switch (stage) {
            case 0:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-0" type="png" />
                </div>
            case 1:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-20" type="png" />
                </div>
            case 2:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-50" type="png" />
                </div>
            case 3:
                return <div className={E("test__progressbar-container")}>
                    <ProgressBar name="progress-bar-90" type="png" />
                </div>
        }
    }

    const renderImageQuestion = (question: number) => {
        //display image based on randomized number
        switch (question) {
            case 1:
                return <>
                    <div className="insContainer">Count the number of Parrots!</div>

                    <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                        <img src={jungle} className={`${letterClassName} opacity100`} />
                    </div>
                </>
            case 2:
                return <>
                    <div className="insContainer">Count the number of Bats!</div>

                    <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                        <img src={cave} className={`${letterClassName} opacity100`} />
                    </div>
                </>
            case 3:
                return <>
                    <div className="insContainer">Count the number of Jellyfish!</div>

                    <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                        <img src={underwater} className={`${letterClassName} opacity100`} />
                    </div>
                </>
        }
    }

    const renderAnswerGrid = (stage: number, question: number) => {
        if (question === 3) {
            return <>
                <div>
                    <h2>How many jellyfish did you see?</h2>
                </div>

                <div className={E("answer__grid")}>
                    <div className={E("grid-item")} onClick={() => handleOnAnswerClick(2, stage, question)}>
                        2
                    </div>

                    <div className={E("grid-item")} onClick={() => handleOnAnswerClick(3, stage, question)}>
                        3
                    </div>

                    <div className={E("grid-item")} onClick={() => handleOnAnswerClick(4, stage, question)}>
                        4
                    </div>

                    <div className={E("grid-item")} onClick={() => handleOnAnswerClick(5, stage, question)}>
                        5
                    </div>
                </div>
            </>
        } else if (question === 2) {
            return <>
                <div>
                    <h2>How many bats did you see?</h2>
                </div>

                <div className={E("answer__grid")}>
                    <div className={E("grid-item")} onClick={() => handleOnAnswerClick(1, stage, question)}>
                        1
                    </div>

                    <div className={E("grid-item")} onClick={() => handleOnAnswerClick(2, stage, question)}>
                        2
                    </div>

                    <div className={E("grid-item")} onClick={() => handleOnAnswerClick(3, stage, question)}>
                        3
                    </div>

                    <div className={E("grid-item")} onClick={() => handleOnAnswerClick(4, stage, question)}>
                        4
                    </div>
                </div>
            </>
        } else if (question === 1) {
            return <>
                <div>
                    <h2>How many parrots did you see?</h2>
                </div>

                <div className={E("answer__grid")}>
                    <div className={E("grid-item")} onClick={() => handleOnAnswerClick(1, stage, question)}>
                        1
                    </div>

                    <div className={E("grid-item")} onClick={() => handleOnAnswerClick(2, stage, question)}>
                        2
                    </div>

                    <div className={E("grid-item")} onClick={() => handleOnAnswerClick(3, stage, question)}>
                        3
                    </div>

                    <div className={E("grid-item")} onClick={() => handleOnAnswerClick(4, stage, question)}>
                        4
                    </div>
                </div>
            </>
        }
    }

    const handleOnAnswerClick = (answer: number, stage: number, question: number) => {
        setTimeout(() => {
            if (checkAnswer(answer, question)) {
                handleCorrectAnswer();
                if (stage === 1) {
                    setSwipeableIndex(STAGE2_QUESTION_SLIDER_ID);
                } else if (stage === 2) {
                    setSwipeableIndex(STAGE3_QUESTION_SLIDER_ID);
                } else if (stage === 3) {
                    setSwipeableIndex(RESULT_SLIDER_ID);
                }
            } else {
                handleWrongAnswer();
                if (stage === 1) {
                    setSwipeableIndex(STAGE2_QUESTION_SLIDER_ID);
                } else if (stage === 2) {
                    setSwipeableIndex(STAGE3_QUESTION_SLIDER_ID);
                } else if (stage === 3) {
                    setSwipeableIndex(RESULT_SLIDER_ID);
                }
            }
        }, 250);
    }

    const checkAnswer = (answer: number, question: number) => {
        if (question === 1) {
            if (answer === 4) return true;
            return false;
        } else if (question === 2) {
            if (answer === 3) return true;
            return false;
        } else if (question === 3) {
            if (answer === 5) return true;
            return false;
        }
    }

    const handleCorrectAnswer = () => {
        handleIncrementTotalCorrectAnswers();
    }

    const handleIncrementTotalCorrectAnswers = () => {
        setTotalCorrectAnswers(prevTotal => prevTotal + 1);
    }

    const handleWrongAnswer = () => {
        handleIncrementConsecutiveWrongAnswerCounter();
    }

    const handleIncrementConsecutiveWrongAnswerCounter = () => {
        setConsecutiveWrongAnswerCounter(prevTotal => prevTotal + 1);
    }

    const renderResult = () => {
        if (localStorage.getItem("persistantState") !== null) {
            const localData = JSON.parse(localStorage.getItem("persistantState") || '')
            const userID = localData.user.userInfoId;

            if (testResult === "happy") {
                const result = {
                    lightSensitivityGameResult: "Happy",
                    userInfoId: userID
                }
                fetch('https://ozzy-qa-app-sea-002.azurewebsites.net/ozzyapi/api/OzzyBear/AddResult', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(result),
                })
                    .then((response) => response.json())
                    .catch((error) => {
                        console.error('Error:', error);
                    });

                return <ResultHappy className="bg-yellow" testType='lightsensitivity' />

            } else if (testResult === "neutral") {
                const result = {
                    lightSensitivityGameResult: "Neutral",
                    userInfoId: userID
                }
                fetch('https://ozzy-qa-app-sea-002.azurewebsites.net/ozzyapi/api/OzzyBear/AddResult', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(result),
                })
                    .then((response) => response.json())
                    .catch((error) => {
                        console.error('Error:', error);
                    });

                return <ResultNeutral className="bg-yellow" testType='lightsensitivity' />

            } else {
                const result = {
                    lightSensitivityGameResult: "Sad",
                    userInfoId: userID
                }
                fetch('https://ozzy-qa-app-sea-002.azurewebsites.net/ozzyapi/api/OzzyBear/AddResult', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(result),
                })
                    .then((response) => response.json())
                    .catch((error) => {
                        console.error('Error:', error);
                    });

                return <ResultSad className="bg-yellow" testType='lightsensitivity' />
            }
        } else {
            return <></>
        }
    }

    const dispatchResult = () => {
        let lightSensitivityTestPassed_ = false;
        if (TotalCorrectAnswers > 2) {
            lightSensitivityTestPassed_ = true;
            setTestResult("happy")
        } else if (TotalCorrectAnswers < 2) {
            setTestResult("sad");
        } else if (TotalCorrectAnswers == 2) {
            setTestResult("neutral");
        }

        setTimeout(() => {
            dispatch(userSlice.actions.setLightSensitivityTestCompleted(true));
            dispatch(userSlice.actions.setLightSensitivityTestPassed(lightSensitivityTestPassed_));
            setCheckTestCompletion(true);
        }, 250);
    }

    return (
        <div className={B() + ` page bg-yellow ${pageAnimation || ''}`}>
            <SwipeableViews onChangeIndex={(index) => handleOnSwipe(index)} disabled={slideArray.includes(swipeableIndex)} enableMouseEvents style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={swipeableIndex}>
                {/* SLIDER_ID 0 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    {renderProgressBar(1)}

                    {renderImageQuestion(question1)}

                    <div className='swipeInstruction'>
                        <p>Swipe left to answer</p>
                    </div>
                </div>

                {/* SLIDER_ID 1 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    {renderProgressBar(1)}

                    {renderAnswerGrid(1, question1)}
                </div>

                {/* SLIDER_ID 2 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    {renderProgressBar(2)}

                    {renderImageQuestion(question2)}

                    <div className='swipeInstruction'>
                        <p>Swipe left to answer</p>
                    </div>
                </div>

                {/* SLIDER_ID 3 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    {renderProgressBar(2)}

                    {renderAnswerGrid(2, question2)}
                </div>

                {/* SLIDER_ID 4 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    {renderProgressBar(3)}

                    {renderImageQuestion(question3)}

                    <div className='swipeInstruction'>
                        <p>Swipe left to answer</p>
                    </div>
                </div>

                {/* SLIDER_ID 5 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    {renderProgressBar(3)}

                    {renderAnswerGrid(3, question3)}
                </div>

                {/* SLIDER_ID 6 */}
                <div className="result-emoji">
                    {renderResult()}
                </div>
            </SwipeableViews>

            <Modal show={showExitConfirmationModal} popup closeModalOnOutsideClick={false} hideCloseButton>
                <div className="confirmation">
                    <div className="confirmation__image-container">
                        <img src={backIcon} alt="back-icon" onClick={() => setShowExitConfirmationModal(false)} />
                    </div>

                    <div>
                        <h1>Are you sure?</h1>
                    </div>
                    <caption>Your progress will not be saved if you exit now.</caption>

                    <div className="confirmation__button-container">
                        <Button
                            text="Yes"
                            type="primary"
                            outlined={false}
                            click={() => goToSelectMode()}
                        />
                    </div>

                </div>
            </Modal>

            <Modal show={showEyeTestBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>Wow! That's a first!</h1> <br />
                        <p>You have earned the <p>Eye Test Goalie</p> badge</p>
                    </div>
                    <Badge name="eye-test-badge" />

                    <Button
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeTestBadgeOnClick()}
                    />
                </div>
            </Modal>

            <Modal show={showEyeAmbassadorBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>That's impressive!</h1> <br />
                        <p>You have earned the <p>Eye Test Ambassador</p> badge</p>
                    </div>
                    <Badge name="eye-ambassador-badge" />

                    <Button
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeAmbassadorBadgeOnClick()}
                    />
                </div>
            </Modal>

            <Modal show={showEyeTestChampBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>You're a champ!</h1> <br />
                        <p>You have earned the <p>Eye Test Champ</p> badge</p>
                    </div>
                    <Badge name="eye-test-champ-badge" />

                    <Button
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeTestChampBadgeOnClick()}
                    />
                </div>
            </Modal>
        </div>
    )

}

export default withAnimationTransition(LightSensitivityTest, {
    entrance: 'FADE_IN'
});

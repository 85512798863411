import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userSlice, { getUser } from '../../store/slices/user-slice';
import useGoto from '../../utils/hooks/useGoto';
import routes from '../../constants/routes.constants';

import trophyIcon from '../../assets/svg/icons/trophy-icon.svg';
import personIcon from '../../assets/svg/icons/person-icon.svg';
import homeIcon from '../../assets/svg/icons/home-icon-black.svg';

const BADGES_ID = 1;
const DASHBOARD_ID = 2;
const PROFILE_ID = 3;

interface Props {
    page:any,
    pageFrom: any
}

const Navigation : React.FC<Props> = ({
    page,
    pageFrom
}) => {
    const dispatch = useDispatch();
    const goto = useGoto();
    const [isTrophySelected, setIsTrophySelected] = useState<boolean>(false);
    const [isHomeSelected, setIsHomeSelected] = useState<boolean>(true);
    const [isProfileSelected, setIsProfileSelected] = useState<boolean>(false);

    useEffect(() => {
       if (page === routes.mybadges.id) return toggleSelected(BADGES_ID);
       if (page === routes.dashboard.id) return toggleSelected(DASHBOARD_ID);
       if (page === routes.profile.id) return toggleSelected(PROFILE_ID);
    }, []);
    
    const navOnClick = (navID: number) => {
        setTimeout(() => {
            toggleSelected(navID);
        }, 100);
        
        resetPageFrom();
        resetDashboardSlideID();
        if (navID === BADGES_ID && pageFrom !== routes.mybadges) return goToMyBadges();
        if (navID === DASHBOARD_ID && pageFrom !== routes.dashboard) return goToDashboard();
        if (navID === PROFILE_ID && pageFrom !== routes.profile) return goToProfile();
        
    }

    const toggleSelected = (navID: number) => {
        if (navID === 1) setIsTrophySelected(true); else setIsTrophySelected(false);
        if (navID === 2) setIsHomeSelected(true); else setIsHomeSelected(false);
        if (navID === 3) setIsProfileSelected(true); else setIsProfileSelected(false);
    }

    const goToDashboard = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                pageFrom, 
                routes.dashboard,
                'SLIDE_LEFT',
                500
            );
        }, 250);
    }

    const goToProfile = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                pageFrom, 
                routes.profile,
                'SLIDE_LEFT',
                500
            );
        }, 250);
    }

    const goToMyBadges = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                pageFrom, 
                routes.mybadges,
                'SLIDE_LEFT',
                500
            );
        }, 250);
    }

    const resetPageFrom = () => {
        setTimeout(() => {
            dispatch(userSlice.actions.setPreviousPage(""));
        }, 250);
    }

    const resetDashboardSlideID = () => {
        setTimeout(() => {
            dispatch(userSlice.actions.setDashboardSlideId(1));
        }, 250);
    }



    return (
        <div className="flex-row navigation-wrapper">
            <div className={`navigation__item ${isTrophySelected ? 'selected' : ''}`}>
                <img src={trophyIcon} alt="trophy-icon" onClick={() => navOnClick(BADGES_ID)}/>
            </div>

            <div className={`navigation__item ${isHomeSelected ? 'selected' : ''}`}>
                <img src={homeIcon} alt="person-icon" onClick={() => navOnClick(DASHBOARD_ID)}/>
            </div>

            <div className={`navigation__item ${isProfileSelected ? 'selected' : ''}`}>
                <img src={personIcon} alt="person-icon" onClick={() => navOnClick(PROFILE_ID)}/>
            </div>
        </div>
    )
}

export default Navigation;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import userSlice, { getUser } from '../../store/slices/user-slice';
import badgesSlice, { getBadges } from '../../store/slices/badges-slice';
import Button from '../../components/ui/Button';
import Badge from '../../components/badge/Badge';
import Modal from '../../components/Modal';
import ResultHappy from '../../components/result-emoji/Happy';
import ResultNeutral from '../../components/result-emoji/Neutral';
import ResultSad from '../../components/result-emoji/Sad';
import ProgressBar from '../../components/test-progressbar/ProgressBar';
import backIcon from '../../assets/svg/icons/delete-icon.svg';
import number1 from '../../assets/svg/color-vision-test/number-01.svg';
import number2 from '../../assets/svg/color-vision-test/number-02.svg';
import number4 from '../../assets/svg/color-vision-test/number-04.svg';
import number5 from '../../assets/svg/color-vision-test/number-05.svg';
import number6 from '../../assets/svg/color-vision-test/number-06.svg';
import number7 from '../../assets/svg/color-vision-test/number-07.svg';
import number8 from '../../assets/svg/color-vision-test/number-08.svg';
import number9 from '../../assets/svg/color-vision-test/number-09.svg';
import coverLeftEye from '../../assets/svg/pop-up/cover-left-eye.svg';

const QUESTION1_SLIDER_ID = 0;
const ANSWER1_SLIDER_ID = 1;
const QUESTION2_SLIDER_ID = 2;
const ANSWER2_SLIDER_ID = 3;
const QUESTION3_SLIDER_ID = 4;
const ANSWER3_SLIDER_ID = 5;
const QUESTION4_SLIDER_ID = 6;
const ANSWER4_SLIDER_ID = 7;
const POPUP_SLIDER_ID = 8;
const QUESTION5_SLIDER_ID = 9;
const ANSWER5_SLIDER_ID = 10;
const QUESTION6_SLIDER_ID = 11;
const ANSWER6_SLIDER_ID = 12;
const QUESTION7_SLIDER_ID = 13;
const ANSWER7_SLIDER_ID = 14;
const QUESTION8_SLIDER_ID = 15;
const ANSWER8_SLIDER_ID = 16;
const RESULT_SLIDER_ID = 17;

const slideArray = [
    ANSWER1_SLIDER_ID,
    ANSWER2_SLIDER_ID,
    ANSWER3_SLIDER_ID,
    ANSWER4_SLIDER_ID,
    ANSWER5_SLIDER_ID,
    ANSWER6_SLIDER_ID,
    ANSWER7_SLIDER_ID,
    ANSWER8_SLIDER_ID
]

interface Props {

}

const ColorVisionTest: React.FC<Props> = () => {
    // VARIABLES
    const [B, E] = useBEM(routes.colorvisiontest.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { id } = useSelector(getUser);
    const dispatch = useDispatch();
    const { badgesEarned, progress, notifications } = useSelector(getUser);
    const { distanceTestCompleted, astigmatismTestCompleted, nearVisionTestCompleted,
        colorVisionTestCompleted, lightSensitivityTestCompleted } = useSelector(getUser);
    const { distanceTestPassed, astigmatismTestPassed, nearVisionTestPassed,
        colorVisionTestPassed, lightSensitivityTestPassed } = useSelector(getUser);
    const { eyeTestBadge, eyeAmbassadorBadge, eyeTestChampBadge } = useSelector(getBadges);
    //const [slideViewID, setSlideViewID] = useState<number>(QUESTION1_SLIDER_ID);
    const [currentQuestion, setCurrentQuestion] = useState<number>(6);
    const [currentAnswer, setCurrentAnswer] = useState<number>(0);
    const [passedEyeTest, setPassedEyeTest] = useState<boolean>(true);
    const [showAnswerFeedBackModal, setShowAnswerFeedBackModal] = useState<boolean>(false);
    const [showCoverLeftEyeModal, setShowCoverLeftEyeModal] = useState<boolean>(false);
    const [showEyeTestBadgeModal, setShowEyeTestBadgeModal] = useState<boolean>(false);
    const [showExitConfirmationModal, setShowExitConfirmationModal] = useState<boolean>(false);
    const [imageAnimation, setImageAnimation] = useState<boolean>(false);
    const [pageAnimation, setPageAnimation] = useState<string>("");
    const [pageTitleAnimation, setPageTitleAnimation] = useState<string>("");
    const [allTestCompleted, setAllTestCompleted] = useState<boolean>(false);
    const [allTestPassed, setAllTestPassed] = useState<boolean>(false);
    const [showEyeAmbassadorBadgeModal, setShowEyeAmbassadorBadgeModal] = useState<boolean>(false);
    const [showEyeTestChampBadgeModal, setShowEyeTestChampBadgeModal] = useState<boolean>(false);
    const [checkTestCompletion, setCheckTestCompletion] = useState<boolean>(false);
    const [swipeableIndex, setSwipeableIndex] = useState<number>(0);

    const swipeableStyles = {
        root: {
            padding: '0',
            height: '100%',
            width: '100%'
        },
        slideContainer: {
            padding: '0'
        }
    };

    let notificationCount = 0;

    // HOOKS
    useEffect(() => {
        connect();
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    const gotoName = () => {
        goto.routeWithTransition(
            routes.colorvisiontest,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }

    useEffect(() => {
        console.log(swipeableIndex);
        if (swipeableIndex === POPUP_SLIDER_ID) return setShowCoverLeftEyeModal(true);
        if (swipeableIndex === RESULT_SLIDER_ID && !eyeTestBadge) setShowEyeTestBadgeModal(true);
        if (swipeableIndex === RESULT_SLIDER_ID) return dispatchResult();
    }, [swipeableIndex]);

    useEffect(() => {
        if (checkTestCompletion) {
            setAllTestCompleted(checkIfAllTestCompleted());
            setAllTestPassed(checkIfAllTestPassed());
        }
    }, [checkTestCompletion]);

    useEffect(() => {
        if (allTestCompleted) {
            if (swipeableIndex === RESULT_SLIDER_ID && !eyeAmbassadorBadge) setShowEyeAmbassadorBadgeModal(true);
        }
    }, [allTestCompleted]);

    useEffect(() => {
        if (allTestPassed) {
            if (swipeableIndex === RESULT_SLIDER_ID && !eyeTestChampBadge) setShowEyeTestChampBadgeModal(true);
        }
    }, [allTestPassed]);

    useEffect(() => {
        if (imageAnimation) {
            setPageAnimation("expand-page");
            setPageTitleAnimation("expand-title");
        } else {
            setPageAnimation("");
            setPageTitleAnimation("");
        }
    }, [imageAnimation]);

    useEffect(() => {
        if (imageAnimation) toggleExpandAnimation();
        // setTimeout(() => {
        //     autoSlide();
        // }, 5000);
    }, [swipeableIndex]);

    //METHODS
    const goToSelectMode = () => {
        updateNotificationInterruptedTest();
        setTimeout(() => {
            goto.routeWithTransition(
                routes.colorvisiontest,
                routes.selectmode,
                'SLIDE_UP',
                500
            );
        }, 250);
    }

    const getNotificationCount = () => {
        let count = 0;
        notifications?.forEach(() => {
            count = count + 1;
        });
        return count;
    }

    const updateNotificationsFirstEyeTestComplete = () => {
        notificationCount = getNotificationCount();
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> =
            ([{ id: notificationCount + 1, description: "Congratulations! You've completed your first eye test! Keep going!", activity: "Completed first eye test (either on Story mode or Explorer mode)", new: true },
            { id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true }]);
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const updateNotificationsAllTestComplete = () => {
        notificationCount = getNotificationCount();
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> =
            ([{ id: notificationCount + 1, description: "Congratulations! You have just completed all the eye tests! Take note of your results on the Results Page and head on to the Charitable Clinic Search page to call your desired clinic.", activity: "Completed all eye tests", new: true },
            { id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true }]);
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const updateNotificationsAllTestPassed = () => {
        notificationCount = getNotificationCount();
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> =
            ([{ id: notificationCount + 1, description: "Congratulations! You have just passed all the eye tests! Take note of your results on the Results Page and head on to the Charitable Clinic Search page to call your desired clinic.", activity: "Completed all eye tests", new: true },
            { id: notificationCount + 2, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true }]);
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const updateNotificationInterruptedTest = () => {
        notificationCount = getNotificationCount();
        let notifs_: Array<{ id: number, description: string, activity: string, new: boolean }> =
            [{ id: notificationCount + 1, description: "Oh sad, You haven't completed your eye tests yet. :(. Would you like to complete it now? Head to Explorer Mode to complete your previously started test!", activity: "Interrupted eye test completion", new: true }];
        dispatch(userSlice.actions.saveNotifications({ notification: notifs_ }));
    }

    const checkIfAllTestCompleted = () => {
        let retVal = true;
        if (!distanceTestCompleted || !astigmatismTestCompleted || !nearVisionTestCompleted ||
            !colorVisionTestCompleted || !lightSensitivityTestCompleted) retVal = false;
        return retVal;
    }

    const checkIfAllTestPassed = () => {
        let retVal = true;
        if (!distanceTestPassed || !astigmatismTestPassed || !nearVisionTestPassed ||
            !colorVisionTestPassed || !lightSensitivityTestPassed) retVal = false;
        return retVal;
    }

    // const autoSlide = () => {
    //     switch (slideViewID) {
    //         case QUESTION1_SLIDER_ID:
    //             return setSlideViewID(ANSWER1_SLIDER_ID);
    //         case QUESTION2_SLIDER_ID:
    //             return setSlideViewID(ANSWER2_SLIDER_ID);
    //         case QUESTION3_SLIDER_ID:
    //             return setSlideViewID(ANSWER3_SLIDER_ID);
    //         case QUESTION4_SLIDER_ID:
    //             return setSlideViewID(ANSWER4_SLIDER_ID);
    //         case QUESTION5_SLIDER_ID:
    //             return setSlideViewID(ANSWER5_SLIDER_ID);
    //         case QUESTION6_SLIDER_ID:
    //             return setSlideViewID(ANSWER6_SLIDER_ID);
    //         case QUESTION7_SLIDER_ID:
    //             return setSlideViewID(ANSWER7_SLIDER_ID);
    //         case QUESTION8_SLIDER_ID:
    //             return setSlideViewID(ANSWER8_SLIDER_ID);
    //     }
    // }

    const toggleExpandAnimation = () => {
        setImageAnimation(prevState => !prevState);
    }

    const proceedOnClick = () => {
        setShowCoverLeftEyeModal(false);
        setSwipeableIndex(QUESTION5_SLIDER_ID);
    }

    const claimEyeTestBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress < 50) progress_ = 50;
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setEyeTestBadge(true));
        }, 250);
        updateNotificationsFirstEyeTestComplete();
        setShowEyeTestBadgeModal(false);
    }

    const claimEyeAmbassadorBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress <= 75) progress_ = 75;
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setEyeAmbassadorBadge(true));
        }, 250);
        updateNotificationsAllTestComplete();
        setShowEyeAmbassadorBadgeModal(false);
    }

    const claimEyeTestChampBadgeOnClick = () => {
        let badgesEarned_ = 0;
        let progress_ = 0;
        if (badgesEarned) badgesEarned_ = badgesEarned + 1;
        if (progress && progress <= 75) progress_ = 75;
        setTimeout(() => {
            dispatch(userSlice.actions.setBadgesEarned(badgesEarned_));
            dispatch(userSlice.actions.setProgress(progress_));
            dispatch(badgesSlice.actions.setEyeTestChampBadge(true));
        }, 250);
        updateNotificationsAllTestPassed();
        setShowEyeTestChampBadgeModal(false);
    }

    const handleIncrementSlideViewID = () => {
        setSwipeableIndex(prevID => prevID + 1);
    }

    const handleOnAnswerClick = (question: number, answer: number) => {
        setCurrentQuestion(question);
        setCurrentAnswer(answer);
        if (question !== answer) setPassedEyeTest(false);
        setShowAnswerFeedBackModal(true);
    }

    const handleOnCloseFeedbackModal = () => {
        setShowAnswerFeedBackModal(false);
        handleIncrementSlideViewID();
    }

    const handleOnSwipe = (index: any) => {
        setSwipeableIndex(index);
    }

    const renderAnswerFeedBack = () => {
        if (currentAnswer === currentQuestion) {
            return <div>
                <h1>Your answer is correct!</h1>
            </div>
        } else {
            return <div>
                <h1>Your answer is incorrect!</h1>
            </div>
        }
    }

    const renderAnswer = (question: number) => {
        return <div className={E("answer__grid")}>
            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 6)}>
                <h1>6</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 7)}>
                <h1>7</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 8)}>
                <h1>8</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 9)}>
                <h1>9</h1>
            </div>
        </div>
    }

    const renderAnswerX789 = (question: number) => {
        return <div className={E("answer__grid")}>
            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 6)}>
                <h1>X</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 7)}>
                <h1>7</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 8)}>
                <h1>8</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 9)}>
                <h1>9</h1>
            </div>
        </div>
    }

    const renderAnswer6X89 = (question: number) => {
        return <div className={E("answer__grid")}>
            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 6)}>
                <h1>6</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 7)}>
                <h1>X</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 8)}>
                <h1>8</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 9)}>
                <h1>9</h1>
            </div>
        </div>
    }

    const renderAnswer67X9 = (question: number) => {
        return <div className={E("answer__grid")}>
            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 6)}>
                <h1>6</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 7)}>
                <h1>7</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 8)}>
                <h1>X</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 9)}>
                <h1>9</h1>
            </div>
        </div>
    }

    const renderAnswer678X = (question: number) => {
        return <div className={E("answer__grid")}>
            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 6)}>
                <h1>6</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 7)}>
                <h1>7</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 8)}>
                <h1>8</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 9)}>
                <h1>X</h1>
            </div>
        </div>
    }

    const renderAnswer1245 = (question: number) => {
        return <div className={E("answer__grid")}>
            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 1)}>
                <h1>1</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 2)}>
                <h1>2</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 5)}>
                <h1>5</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 4)}>
                <h1>4</h1>
            </div>
        </div>
    }

    const renderAnswerX254 = (question: number) => {
        return <div className={E("answer__grid")}>
            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 1)}>
                <h1>X</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 2)}>
                <h1>2</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 5)}>
                <h1>5</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 4)}>
                <h1>4</h1>
            </div>
        </div>
    }

    const renderAnswer1X54 = (question: number) => {
        return <div className={E("answer__grid")}>
            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 1)}>
                <h1>1</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 2)}>
                <h1>X</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 5)}>
                <h1>5</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 4)}>
                <h1>4</h1>
            </div>
        </div>
    }

    const renderAnswer12X4 = (question: number) => {
        return <div className={E("answer__grid")}>
            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 1)}>
                <h1>1</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 2)}>
                <h1>2</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 5)}>
                <h1>X</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 4)}>
                <h1>4</h1>
            </div>
        </div>
    }

    const renderAnswer125X = (question: number) => {
        return <div className={E("answer__grid")}>
            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 1)}>
                <h1>1</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 2)}>
                <h1>2</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 5)}>
                <h1>5</h1>
            </div>

            <div className={E("grid-item")} onClick={() => handleOnAnswerClick(question, 4)}>
                <h1>X</h1>
            </div>
        </div>
    }

    const renderResult = () => {
        if (localStorage.getItem("persistantState") !== null) {
            const localData = JSON.parse(localStorage.getItem("persistantState") || '')
            const userID = localData.user.userInfoId;

            if (passedEyeTest) {
                const result = {
                    colorGameResult: "Happy",
                    userInfoId: userID
                }
                fetch('https://ozzy-qa-app-sea-002.azurewebsites.net/ozzyapi/api/OzzyBear/AddResult', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(result),
                })
                    .then((response) => response.json())
                    .catch((error) => {
                        console.error('Error:', error);
                    });

                return <ResultHappy className="bg-blue" testType='colorvision' />

            } else {
                const result = {
                    colorGameResult: "Sad",
                    userInfoId: userID
                }
                fetch('https://ozzy-qa-app-sea-002.azurewebsites.net/ozzyapi/api/OzzyBear/AddResult', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(result),
                })
                    .then((response) => response.json())
                    .catch((error) => {
                        console.error('Error:', error);
                    });

                return <ResultSad className="bg-blue" testType='colorvision' />

            }
        } else {
            return <></>
        }
    }

    const dispatchResult = () => {
        let colorVisionTestPassed_ = true;
        if (!passedEyeTest) colorVisionTestPassed_ = false;

        setTimeout(() => {
            dispatch(userSlice.actions.setColorVisionTestCompleted(true));
            dispatch(userSlice.actions.setColorVisionTestPassed(colorVisionTestPassed_));
            setCheckTestCompletion(true);
        }, 250);
    }

    const renderPageTitle = () => {
        return <div className={`flex-row title-wrapper ${pageTitleAnimation || ''}`}>
            <img src={backIcon} alt="back-icon" onClick={() => setShowExitConfirmationModal(true)} />
            <h1>Color Vision Game</h1>
        </div>
    }

    return (
        <div className={B() + ` page bg-blue ${pageAnimation || ''}`}>
            <SwipeableViews onChangeIndex={(index) => handleOnSwipe(index)} disabled={slideArray.includes(swipeableIndex)} enableMouseEvents style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer} index={swipeableIndex}>
                {/* SLIDER_ID 0 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-0" type="png" />
                    </div>

                    <div>
                        <h2>What number do you see?</h2>
                    </div>

                    <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                        <img src={number6} alt="number-06" />
                    </div>

                    <div className="swipeInstruction">
                        <p>Swipe left to answer</p>
                    </div>
                </div>

                {/* SLIDER_ID 1 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-0" type="png" />
                    </div>

                    <div>
                        <h2>What number did you see?</h2>
                    </div>

                    {renderAnswer678X(6)}
                </div>

                {/* SLIDER_ID 2 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-20" type="png" />
                    </div>

                    <div>
                        <h2>What number do you see?</h2>
                    </div>

                    <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                        <img src={number7} alt="number-07" />
                    </div>

                    <div className="swipeInstruction">
                        <p>Swipe left to answer</p>
                    </div>
                </div>

                {/* SLIDER_ID 3 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-20" type="png" />
                    </div>

                    <div>
                        <h2>What number did you see?</h2>
                    </div>

                    {renderAnswer67X9(7)}
                </div>

                {/* SLIDER_ID 4 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-30" type="png" />
                    </div>

                    <div>
                        <h2>What number do you see?</h2>
                    </div>

                    <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                        <img src={number8} alt="number-08" />
                    </div>

                    <div className="swipeInstruction">
                        <p>Swipe left to answer</p>
                    </div>
                </div>

                {/* SLIDER_ID 5 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-30" type="png" />
                    </div>

                    <div>
                        <h2>What number did you see?</h2>
                    </div>

                    {renderAnswerX789(8)}
                </div>

                {/* SLIDER_ID 6 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-40" type="png" />
                    </div>

                    <div>
                        <h2>What number do you see?</h2>
                    </div>

                    <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                        <img src={number9} alt="number-09" />
                    </div>

                    <div className="swipeInstruction">
                        <p>Swipe left to answer</p>
                    </div>
                </div>

                {/* SLIDER_ID 7 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-40" type="png" />
                    </div>

                    <div>
                        <h2>What number did you see?</h2>
                    </div>

                    {renderAnswer6X89(9)}
                </div>

                {/* SLIDER_ID 8 */}
                <div className="flex-col">

                </div>

                {/* SLIDER_ID 9 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-50" type="png" />
                    </div>

                    <div>
                        <h2>What number do you see?</h2>
                    </div>

                    <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                        <img src={number1} alt="number-01" />
                    </div>

                    <div className="swipeInstruction">
                        <p>Swipe left to answer</p>
                    </div>
                </div>

                {/* SLIDER_ID 10 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-50" type="png" />
                    </div>

                    <div>
                        <h2>What number did you see?</h2>
                    </div>

                    {renderAnswer1X54(1)}
                </div>

                {/* SLIDER_ID 11 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-60" type="png" />
                    </div>

                    <div>
                        <h2>What number do you see?</h2>
                    </div>

                    <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                        <img src={number5} alt="number-05" />
                    </div>

                    <div className="swipeInstruction">
                        <p>Swipe left to answer</p>
                    </div>
                </div>

                {/* SLIDER_ID 12 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-60" type="png" />
                    </div>

                    <div>
                        <h2>What number did you see?</h2>
                    </div>

                    {renderAnswerX254(5)}
                </div>

                {/* SLIDER_ID 13 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-70" type="png" />
                    </div>

                    <div>
                        <h2>What number do you see?</h2>
                    </div>

                    <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                        <img src={number4} alt="number-04" />
                    </div>

                    <div className="swipeInstruction">
                        <p>Swipe left to answer</p>
                    </div>
                </div>

                {/* SLIDER_ID 14 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-70" type="png" />
                    </div>

                    <div>
                        <h2>What number did you see?</h2>
                    </div>

                    {renderAnswer12X4(4)}
                </div>

                {/* SLIDER_ID 15 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-80" type="png" />
                    </div>

                    <div>
                        <h2>What number do you see?</h2>
                    </div>

                    <div className={E("test__image-container", [imageAnimation ? 'animation-expand' : ''])} onClick={toggleExpandAnimation}>
                        <img src={number2} alt="number-02" />
                    </div>

                    <div className="swipeInstruction">
                        <p>Swipe left to answer</p>
                    </div>
                </div>

                {/* SLIDER_ID 16 */}
                <div className="flex-col">
                    {renderPageTitle()}

                    <div className={E("test__progressbar-container")}>
                        <ProgressBar name="progress-bar-80" type="png" />
                    </div>

                    <div>
                        <h2>What number did you see?</h2>
                    </div>

                    {renderAnswer12X4(2)}
                </div>

                {/* SLIDER_ID 17 */}
                <div className="result-emoji">
                    {renderResult()}
                </div>
            </SwipeableViews>

            <Modal show={showCoverLeftEyeModal} popup closeModalOnOutsideClick={false} hideCloseButton>
                <div className="popup">
                    <div className="popup__image">
                        <img src={coverLeftEye} alt="cover-left-eye" />
                    </div>

                    <div>
                        <h1>Well done!</h1>
                    </div>

                    <div>
                        <caption>Now, cover your right eye.</caption>
                    </div>

                    <div className="popup__button">
                        <Button
                            text="Proceed"
                            type="primary"
                            outlined={false}
                            click={() => proceedOnClick()}
                        />
                    </div>
                </div>
            </Modal>

            <Modal show={showAnswerFeedBackModal} popup closeModalOnOutsideClick={false} hideCloseButton>
                <div className="confirmation__answerfeedback">
                    {renderAnswerFeedBack()}

                    <div>
                        <caption>Everyone should see the number {currentQuestion}</caption>
                    </div>

                    <div className="confirmation__button-container">
                        <Button
                            text="Proceed"
                            type="primary"
                            outlined={false}
                            click={() => handleOnCloseFeedbackModal()}
                        />
                    </div>

                </div>
            </Modal>

            <Modal show={showExitConfirmationModal} popup closeModalOnOutsideClick={false} hideCloseButton>
                <div className="confirmation">
                    <div className="confirmation__image-container">
                        <img src={backIcon} alt="back-icon" onClick={() => setShowExitConfirmationModal(false)} />
                    </div>

                    <div>
                        <h1>Are you sure?</h1>
                    </div>

                    <div>
                        <caption>Your progress will not be saved if you exit now.</caption>
                    </div>

                    <div className="confirmation__button-container">
                        <Button
                            text="Yes"
                            type="primary"
                            outlined={false}
                            click={() => goToSelectMode()}
                        />
                    </div>

                </div>
            </Modal>

            <Modal show={showEyeTestBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>Wow! That's a first!</h1> <br />
                        <caption>You have earned the <p>Eye Test Goalie</p> badge</caption>
                    </div>
                    <Badge name="eye-test-badge" />

                    <Button
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeTestBadgeOnClick()}
                    />
                </div>
            </Modal>

            <Modal show={showEyeAmbassadorBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>That's impressive!</h1> <br />
                        <caption>You have earned the <p>Eye Test Ambassador</p> badge</caption>
                    </div>
                    <Badge name="eye-ambassador-badge" />

                    <Button
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeAmbassadorBadgeOnClick()}
                    />
                </div>
            </Modal>

            <Modal show={showEyeTestChampBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>You're a champ!</h1> <br />
                        <caption>You have earned the <p>Eye Test Champ</p> badge</caption>
                    </div>
                    <Badge name="eye-test-champ-badge" />

                    <Button
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeTestChampBadgeOnClick()}
                    />
                </div>
            </Modal>
        </div>
    )

}

export default withAnimationTransition(ColorVisionTest, {
    entrance: 'FADE_IN'
});
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import routes from '../../constants/routes.constants';
import useIndexedDB from '../../utils/hooks/useIndexedDB';
import userSlice, { getUser } from '../../store/slices/user-slice'; 
import badgesSlice, { getBadges } from '../../store/slices/badges-slice';
import Button from '../../components/ui/Button';
import Badge from '../../components/badge/Badge';
import Modal from '../../components/Modal';

import backIcon from '../../assets/svg/icons/arrow-back-white.svg';
import the2HourRule from '../../assets/svg/eyecare-tips/the-2-hour-rule.svg';
import the2020Rule from '../../assets/svg/eyecare-tips/the-20-20-rule.svg';
import theElbowRule from '../../assets/svg/eyecare-tips/the-elbow-rule.svg';
import theOutdoorsRule from '../../assets/svg/eyecare-tips/the-outdoors-rule.svg';


interface Props {

}

const EyeCareTips : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM(routes.eyecaretips.id);
    const goto = useGoto();
    const [connect] = useIndexedDB();
    const { id } = useSelector(getUser);
    const dispatch = useDispatch();
    const { progress, badgesEarned, notifications } = useSelector(getUser);
    const [progress_, setProgress] = useState<number>(0);
    const [badgesEarned_, setBadgesEarned] = useState<number>(0);
    const { eyeTipsChampBadge } = useSelector(getBadges);
    const [showEyeTipsChampBadgeModal, setShowEyeTipsChampBadgeModal] = useState(false);

    let notificationCount = 0;
    // HOOKS
    useEffect(() => {
        connect();
        setPageFrom();
        checkEyeTipsChampBadgeModal();
    }, []);

    useEffect(() => {
        if (!id) gotoName();
    }, []);

    const gotoName = () => {
        goto.routeWithTransition(
            routes.eyecaretips,
            routes.userinfo,
            'SLIDE_DOWN',
            500
        );
    }

    //METHODS
    const goToDashboard = () => {
        setTimeout(() => {
            goto.routeWithTransition(
                routes.eyecaretips, 
                routes.dashboard,
                'SLIDE_DOWN',
                500
            );
        }, 250);
    }
    
    const getNotificationCount = () => {
        let count = 0;
        notifications?.forEach(() => {
            count = count + 1;
        });
       
        return count;
    }

    const updateNotificationsNewBadge = () => {
        let notifs_:Array<{id: number, description: string, activity: string, new:boolean}> = 
            [{id: notificationCount + 1, description: "Congrats! You've earned a badge!", activity: "Completed specific activity and earned a badge", new: true}];
        dispatch(userSlice.actions.saveNotifications({notification:notifs_}));
    }

    const checkEyeTipsChampBadgeModal = () => {
        if (!eyeTipsChampBadge) {
            if (progress) setProgress(progress);
            if (badgesEarned) setBadgesEarned(badgesEarned);
            setShowEyeTipsChampBadgeModal(true);
        } 
    }

    
    const claimEyeTipsChampBadgeOnClick = () => {
        let iBagesEarned = badgesEarned_;
        let iProgress = progress_;

        if (iProgress < 50) { iProgress = 40 }

        setTimeout(() => {
            
            setTimeout(() => {
                const user = {
                    progress: iProgress, badgesEarned: iBagesEarned+1
                }
                const badges = {
                    eyeTipsChampBadge: true
                }
            dispatch(userSlice.actions.setUserData(user));
            dispatch(badgesSlice.actions.setBadgesData(badges));
            }, 250);
        }, 250);
        notificationCount = getNotificationCount();
        updateNotificationsNewBadge();
        setShowEyeTipsChampBadgeModal(false);
    }

    const setPageFrom = () => {
        setTimeout(() => {
            dispatch(userSlice.actions.setPreviousPage(routes.eyecaretips.id));
        }, 250);
    }


    return(
        <div className={B() + ' page bg-light'}>
            <div className="flex-col">
                <div className="flex-row title-wrapper"> 
                    <img src={backIcon} alt="back-icon" onClick={() => goToDashboard()}/>                        
                    <h1>Eye Care Tips</h1>
                </div>

                <div>
                    <div className={E("tips-container")}>
                        
                        <img src={the2HourRule} alt="the-2-hour-rule"/>
                        
                        <div className={E("tips__title-description")}>
                            <div className={E("tips--title")}>
                                <h2>The 2 Hour Rule:</h2>
                            </div>

                            <div className={E("tips--description")}>
                                <caption>Less than 2 hours screen time and don't sit too long</caption>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("tips-container")}>
                       
                        <img src={the2020Rule} alt="the-20-20-rule"/>
                    
                        <div className={E("tips__title-description")}>
                            <div className={E("tips--title")}>
                                <h2>The 20/20 Rule:</h2>
                            </div>

                            <div className={E("tips--description")}>
                                <caption>With every 20 minutes of reading, take a break for 20 seconds</caption>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div>
                    <div className={E("tips-container")}>
                        <img src={theElbowRule} alt="the-elbow-rule"/>
                    
                        <div className={E("tips__title-description")}>
                            <div className={E("tips--title")}>
                                <h2>The Elbow Rule:</h2>
                            </div>

                            <div className={E("tips--description")}>
                                <caption>Keep forearm distance between eyes and book or gadget</caption>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={E("tips-container")}>
                        <img src={theOutdoorsRule} alt="the-outdoors-rule"/>
                    
                        <div className={E("tips__title-description")}>
                            <div className={E("tips--title")}>
                                <h2>The Outdoors Rule:</h2>
                            </div>

                            <div className={E("tips--description")}>
                                <caption>Have at least 90 minutes a day outdoors, be sun smart and active</caption>
                            </div>
                        </div>
                    </div>
                </div>

            
            </div>

            <Modal show={showEyeTipsChampBadgeModal} badge closeModalOnOutsideClick={false} hideCloseButton>
                <div className="badge">
                    <div>
                        <h1>Good learning!</h1> <br />
                        <caption>You have earned the <p>Eye Tipster</p> badge</caption>
                    </div>
                    <Badge name="eye-tips-champ-badge"/>

                    <Button 
                        text="Claim Badge"
                        type="primary"
                        outlined={false}
                        click={() => claimEyeTipsChampBadgeOnClick()}
                    /> 
                </div>
            </Modal>
           
        </div>
    )

}

export default withAnimationTransition(EyeCareTips, {
    entrance: 'FADE_IN'
});
